import { useContext, useEffect, useState } from 'react';
import ReactFlow, {
  ReactFlowProvider, MiniMap, Controls
} from 'react-flow-renderer';
import { Helmet } from "react-helmet";

import "./FlowContainer.css";

import NodeContext from "../context/NodeContext";
import { INCREMENT_SIZE } from "../constants/FlowConstants";

import { getLayoutedElements } from "../services/LayoutService";

import 'react-flow-renderer/dist/style.css';
import 'react-flow-renderer/dist/theme-default.css';
import { setNodeStyle } from '../utils/NodeUtils';

const nodeExtent = [
  [0, 0],
  [Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY],
];
const FlowContainer = ({ systemId, source, closeDrawer, openDrawer }) => {
  const { setActiveNodeId, elements, setElements } = useContext(NodeContext);
  const { dataHandler } = useContext(NodeContext);

  // Workaround to ensure that fitView is only run once
  const [viewFitRun, setViewFitRun] = useState(false);

  const [reactFlowInstance, setReactFlowInstance] = useState(null);

  // Cache the title node for metadata tags
  const [titleNode, setTitleNode] = useState(undefined);


  useEffect(() => {
    setViewFitRun(false);
    if (systemId) {
      // Determine when to use cached system vs. fresh pull
      let useLatest = true;
      if (source && source === "library") {
        useLatest = false;
      } else if (source && source === "flow") {
        useLatest = false;
      } else if (source && source === "landing") {
        useLatest = true;
      }

      dataHandler.doGetSystem(systemId, useLatest).then((system) => {
        // console.log(system); // debug
        if (system) {
          setElements(getLayoutedElements(system.elements.map(setNodeStyle)));
          setTitleNode(system.elements[0]);
        }
      })
    }
    // eslint-disable-next-line
  }, [systemId]);

  useEffect(() => {
    if (reactFlowInstance && !viewFitRun && elements && elements.length) {
      // Only apply fit view when there's a few nodes or else it looks too zoomed in
      if (elements.length > 7) {
        reactFlowInstance.fitView();
      }
      setViewFitRun(true);
    }
    // eslint-disable-next-line
  }, [reactFlowInstance]);

  const onLoadHandler = (rf) => {
    setReactFlowInstance(rf);
  }

  const snapGrid = [INCREMENT_SIZE, INCREMENT_SIZE];

  const handleNodeSelection = (event) => {
    // react-flow__edge-path is a SVGAnimatedString while react-flow__node is a string. Rely on type check
    // to determine when a node or edge has been selected so we only show for nodes.
    if (typeof (event.target.className) === `string`) {
      const sourceNodeId = event.target.dataset.id;
      // Set the active node
      setActiveNodeId(sourceNodeId);
      // Display the popover
      openDrawer();
    }
  }

  const formatSystemName = (titleNode) => {
    // TODO: turn into util, move strings to constants
    let title;
    if (titleNode.metadata.name === "Untitled System") {
      title = "BuddyTape: Build BJJ Flow Charts";
    } else {
      title = titleNode.metadata.name.split(' ').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ')
        + " - BuddyTape: Build BJJ Flow Charts"
    }
    return title;
  }

  const formatSystemDescription = (titleNode) => {
    // TODO: turn into util, move strings to constants
    let description;
    if (!titleNode.metadata.description || titleNode.metadata.description.length < 1) {
      description = "Free, no-nonsense app designed for you to build and manage Brazilian Jiu Jitsu (BJJ) flow charts."
    } else {
      description = titleNode.metadata.description;
    }
    return description;
  }

  return (
    <>
      {/*<br/><br/><IonButton onClick={() => {history.push("library")}}>test</IonButton>*/}

      {titleNode &&
        <Helmet>
          <title>{formatSystemName(titleNode)}</title>
          <meta name="description" content={formatSystemDescription(titleNode)} />
        </Helmet>
      }
      <ReactFlowProvider>
        <ReactFlow
          elements={elements}
          onLoad={onLoadHandler}
          defaultZoom={1.2}
          onElementClick={handleNodeSelection}
          snapToGrid={true}
          snapGrid={snapGrid}
          nodesDraggable={false}
          nodeExtent={nodeExtent}
        >
          <Controls showInteractive={false} showFitView={true} />
          <MiniMap></MiniMap>
        </ReactFlow>
      </ReactFlowProvider>
    </>
  );
};

export default FlowContainer;