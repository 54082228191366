import FirebaseService from "../services/FirebaseService";
import StorageService from "../services/StorageService";
import { FormError, RegistrationFields, User } from "../types/UserTypes";

// TODO: set up FormError here and not inside of the service

class AuthHandler {
  private firebaseService: FirebaseService;
  private storageService: StorageService;

  constructor(
    firebaseService?: FirebaseService,
    storageService?: StorageService
  ) {
    this.firebaseService = firebaseService;
    this.storageService = storageService;
  }

  getFirebaseService() {
    return this.firebaseService;
  }

  doCreateUser({
    name,
    email,
    password,
  }: RegistrationFields): Promise<FormError> {
    // TODO: migrate service logic handling to here
    return this.firebaseService.doCreateUser({
      name: name,
      email: email,
      password: password,
    });
  }

  async isSignedIn(): Promise<boolean> {
    return await this.storageService.getUser().then((user: User) => {
      if (user) {
        //console.log(user);
        return true;
      } else {
        return this.firebaseService.isSignedIn();
      }
    });
  }

  async doSignInWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<FormError> {
    return new Promise<FormError>(async (resolve) => {
      /* unnecessary unless actually needed
      if (this.firebaseService.isSignedIn()) {
        console.log("Clearing existing user details prior to sign in");
        await this.doSignOut();
      }
      */

      let result: any = undefined;
      try {
        result = await this.firebaseService.doSignInWithEmailAndPassword(
          email,
          password
        );
      } catch (e: any) {
        return {
          hasError: true,
          message: e.message,
        };
      }
      const user: User = this.firebaseService.getUser();

      this.storageService.setUser(user).then((_u: User) => {
        resolve(result);
      });
    });
  }

  async doGoogleSignIn(): Promise<FormError> {
    return new Promise<FormError>(async (resolve) => {
      const result = await this.firebaseService.doGoogleSignIn();
      const user: User = this.firebaseService.getUser();

      this.storageService.setUser(user).then((_u: User) => {
        resolve(result);
      });
    });
  }

  async doSignOut(): Promise<void> {
    await this.storageService.clearUser();
    return await this.firebaseService.doSignOut();
  }

  async doPasswordReset(email: string): Promise<FormError> {
    return this.firebaseService.doPasswordReset(email);
  }

  doPasswordUpdate = async (password: string): Promise<void> => {
    const isSignedIn = await this.isSignedIn();
    if (isSignedIn) {
      return this.firebaseService.doPasswordUpdate(password);
    } else {
      console.warn("User is not signed in");
    }
  };

  async doGetUser(): Promise<User | null> {
    // TODO: add a TTL for user's login
    let currentUser: User = await this.storageService.getUser();

    if (currentUser) {
      return currentUser;
    } else {
      return this.firebaseService.getUser();
    }
  }
}

export default AuthHandler;
