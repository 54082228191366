import { NodeProvider } from "./context/NodeContext";
import FirebaseContext from "./context/FirebaseContext";
import StorageContext from "./context/StorageContext";

import Library from "./pages/Library";
import Main from "./pages/Main";
import Settings from "./pages/Settings";
import Landing from "./pages/Landing";
import Login from "./pages/Login";
import Register from "./pages/Register";
import IntroSlides from "./pages/IntroSlides";

import React from "react";
import { setupIonicReact } from '@ionic/react';
import { IonApp, IonRouterOutlet, IonSplitPane } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "@fontsource/roboto";

import { AuthProvider } from "./context/AuthContext";
import StorageService from "./services/StorageService";

/* Sentry dependencies */
import { createBrowserHistory } from "history";
import { CaptureConsole } from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { NodeWorkflow } from "./pages/NodeWorkflow";
import NodeConnections from "./pages/NodeConnections";
import SideMenu from "./components/SideMenu";
import SystemTagCreation from "./pages/SystemTagCreation";
import { SystemProvider } from "./context/SystemContext";
import { MediaProvider } from "./context/MediaContext";

setupIonicReact({
  mode: 'md'
});

// @ts-ignore
const SentryRoute = Sentry.withSentryRouting(Route);

const history = createBrowserHistory();
if (!window.location.href.includes("8100")) {
  Sentry.init({
    dsn:
      "https://1b5a34ba762b479c81a15a9039eee576@o526382.ingest.sentry.io/5641877",
    release: "buddytape@" + process.env.npm_package_version,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
      new CaptureConsole({ levels: ["warning", "error"] }),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
} else {
  console.debug("Running locally, don't initialize Sentry.");
}

// https://ui.dev/react-router-v5-protected-routes-authentication/
// TODO
/*
const RequireAuth = ({ children, authHandler, ...rest }: any) => {
  if (false) {
    //!fakeAuth.signedIn) {
    return <Redirect to={"/landing"} />;
  }

  return (
    <SentryRoute
      {...rest}
      render={() => {
        authHandler.isSignedIn().then((v: boolean) => {
          if (!v) {
            return <Redirect to="/login" />;
          }
        });
      }}
    />
  );
};
*/

const App: React.FC = () => {
  return (
    <>
      <StorageContext.Consumer>
        {(storageService: StorageService) => (
          <FirebaseContext.Consumer>
            {(v) => (
              <AuthProvider
                firebaseService={v.firebaseService}
                storageService={storageService}
              >
                <NodeProvider
                  firebaseService={v.firebaseService}
                  storageService={storageService}
                >
                  <SystemProvider
                  >
                    <MediaProvider
                    >
                      <IonApp>
                        <IonReactRouter history={history}>
                          <IonSplitPane contentId="main">
                            <SideMenu />
                            <IonRouterOutlet id="main">
                              <SentryRoute
                                path="/main"
                                component={Main}
                                exact={true}
                              />
                              <SentryRoute
                                path="/s/:id"
                                component={Main}
                                exact={true}
                              />
                              <SentryRoute
                                path="/s/:systemId/node"
                                component={NodeWorkflow}
                                exact={true}
                              />
                              <SentryRoute
                                path="/s/:systemId/node/:nodeId/connections"
                                component={NodeConnections}
                                exact={true}
                              />
                              <SentryRoute
                                path="/login"
                                component={Login}
                                exact={true}
                              />
                              <SentryRoute
                                path="/register"
                                component={Register}
                                exact={true}
                              />
                              <SentryRoute
                                path="/"
                                component={Landing}
                                exact={true}
                              />
                              <SentryRoute
                                path="/settings"
                                component={Settings}
                                exact={true}
                              />
                              <SentryRoute
                                path="/library"
                                component={Library}
                                exact={true}
                              />
                              <SentryRoute
                                path="/s/:systemId/tags"
                                component={SystemTagCreation}
                                exact={true}
                              />
                              <SentryRoute
                                path="/intro"
                                component={IntroSlides}
                                exact={true}
                              />
                              <SentryRoute
                                exact
                                path="/landing"
                                render={() => <Redirect to="/" />}
                              />
                            </IonRouterOutlet>
                          </IonSplitPane>
                        </IonReactRouter>
                      </IonApp>
                    </MediaProvider>
                  </SystemProvider>
                </NodeProvider>
              </AuthProvider>
            )}
          </FirebaseContext.Consumer>
        )}
      </StorageContext.Consumer>
    </>
  );
};
export default App;
