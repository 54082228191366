import {
  IonAlert,
  //IonBackdrop, // eslint-disable-line @typescript-eslint/no-unused-vars
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonChip,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonPage,
  IonPopover,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSearchbar,
  IonToast,
  IonToolbar,
  useIonLoading,
} from "@ionic/react";
import { RefresherEventDetail } from "@ionic/core";
import {
  add,
  closeCircle,
  ellipsisVertical,
  filter,
  informationCircle,
} from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";

import { EventType, NodeType } from "../types/NodeTypes";
import { System, Tag, UntaggedTag, UserLibrary } from "../types/SystemTypes";
import { setNodeStyle } from "../utils/NodeUtils";

import "./Library.css";
import AuthContext, { AuthProviderState } from "../context/AuthContext";
import NodeContext, { NodeProviderState } from "../context/NodeContext";
import { LibrarySystemSheet } from "../components/LibrarySystemSheet";
import { getTags, setColor, sortTags } from "../utils/SystemUtils";
import SystemContext from "../context/SystemContext";

// TODO: description does not update until a manual/force refresh

const Library = () => {
  const { authHandler, user }: AuthProviderState = useContext(AuthContext);
  const { dataHandler, elements }: NodeProviderState = useContext(NodeContext);
  const { searchTags, systemUpdated, markSystemUpdated, addSearchTag, removeSearchTag } = useContext(SystemContext);

  const [createSystemAlert, setCreateSystemAlert] = useState(false);

  const location = useLocation()

  // Search settings
  const [allTags, setAllTags] = useState<Array<Tag>>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [filterOptionsPopoverState, setFilterOptionsPopoverState] = useState({ showPopover: false, event: undefined });
  const [toastNotification, setToastNotification] = useState({ show: false, message: "", color: "" });

  const [userLibrary, setUserLibrary] = useState<Array<System>>([]);
  const [presentLoading, dismissLoading] = useIonLoading();

  const history = useHistory();

  const openMenu = () => {
    history.push(`/settings`);
  };

  /* Bottom sheet configurations */
  const [showBottomSheet, setShowBottomSheet] = useState<boolean>(false);
  const [activeSystemId, setActiveSystemId] = useState<string>(null);

  const handleCloseDrawer = () => {
    setActiveSystemId(undefined);
    if (showBottomSheet) {
      setShowBottomSheet(false);
    }
  };

  const handleOpenDrawer = () => {
    if (!showBottomSheet) {
      setShowBottomSheet(true);
    }
  };

  const hideToastNotification = () => {
    setToastNotification({
      show: false,
      message: "",
      color: "",
    })
  }

  const handleFilterOptions = (e: any) => {
    // Handles when we have the "Untagged" filter.
    if (allTags.length < 2) {
      setToastNotification({
        show: true,
        message: "You have no tags! Add tags to your systems to enabling tag filtering.",
        color: "medium"
      })
    } else {
      setFilterOptionsPopoverState({ showPopover: true, event: e });
    }
  }

  const dismissFilterOptionsPopover = (e: any) => {
    setFilterOptionsPopoverState({ showPopover: false, event: undefined })
  }

  const formatDescription = (description: string): string => {
    const MAX_DESCRIPTION_LENGTH = 100;
    if (description.length <= MAX_DESCRIPTION_LENGTH) {
      return description;
    } else {
      return description.substring(0, MAX_DESCRIPTION_LENGTH) + "...";
    }
  };

  const createSystem = (title: string) => {
    if (title === null || title === undefined || title === "") {
      title = "Untitled System";
    }
    const creationTime: string = new Date().toISOString();
    const modifiedTime: string = new Date().toISOString();

    let titleNode: NodeType = setNodeStyle({
      id: "n-0",
      data: {
        label: title,
      },
      metadata: {
        creationTime: creationTime,
        modifiedTime: modifiedTime,
        type: EventType.Title,
        name: title,
        description: "",
      },
      position: { x: 100, y: 100 },
    });

    // Rely on Firebase to generate ID
    let system: System = {
      systemId: null,
      name: title,
      description: "",
      creationTime: creationTime,
      modifiedTime: modifiedTime,
      ownerId: user.uid,
      elements: [titleNode],
      tags: [],
    };

    console.log("Creating system for title " + title);

    dataHandler
      .doAddSystem(system)
      .then((createdSystem: System) => {
        system.systemId = createdSystem.systemId;

        // Update the local data since history.back doesn't run useEffect
        setUserLibrary([system].concat(userLibrary));
        history.push(`/s/${createdSystem.systemId}?source=library`);
      })
      .catch((e: Error) => {
        console.error("Error creating system: " + e);
      });
  };

  useEffect(() => {
    presentLoading({
      duration: 9000
    });
    const unsubscribe = authHandler
      .getFirebaseService()
      .getFirebase()
      .auth()
      .onAuthStateChanged((authUser) => {
        if (authUser && authUser.emailVerified) {
          dataHandler
            .doGetUserLibrary(authUser.uid, false)
            .then((ul: UserLibrary) => {
              //console.log(ul);
              dismissLoading();

              if (ul.systems) {
                setUserLibrary(ul.systems);
                setAllTags(getTags(ul.systems, true));
              }
            });
        } else {
          dismissLoading();

          authHandler.doSignOut();
          history.replace("/landing");
        }
      });

    // Unmount subscriber
    return () => unsubscribe();
  }, [authHandler, dataHandler, history]);

  useEffect(() => {
    if (user !== null && location !== null && location.pathname === "/library") {
      // I give up for now...this should be pulling from the cache
      // TODO: fix cache so newly added items are detected properly from here
      dataHandler.doGetUserLibrary(user.uid, false).then((ul: UserLibrary) => {
        //console.log(ul);
        if (ul.systems) {
          setUserLibrary(ul.systems);
          setAllTags(getTags(ul.systems, true));
        }
      });
    }
  }, [elements, location, systemUpdated])

  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    if (user) {
      dataHandler.doGetUserLibrary(user.uid, false).then((ul: UserLibrary) => {
        //console.log(ul);
        if (ul.systems) {
          setUserLibrary(ul.systems);
        }
      });
    }

    setTimeout(() => {
      //console.log("Async operation has ended");
      event.detail.complete();
    }, 2000);
  }


  const setTagColor = (color: string): Record<string, string> => {
    const tagColorStyle: Record<string, string> = setColor(color);
    tagColorStyle["height"] = "75%";
    return tagColorStyle
  }


  const showTagInFilters = (tag: Tag): boolean => {
    // Determine what tags to show in the search filters
    if (searchTags.includes(tag)) {
      return false;
    } else {
      return true;
    }
  }

  const handleFilterTagSelect = (tag: Tag): void => {
    // Add tags to the existing search tags
    if (!searchTags.includes(tag)) {
      addSearchTag(tag);
    }
  }

  return (
    <IonPage>
      <IonHeader className="ion-no-border" color="light">
        <IonToolbar color="none">
          <IonButtons slot="start">
            <IonButton>
              <IonMenuButton autoHide={true} menu="sideMenu" color="primary"></IonMenuButton>
            </IonButton>
            <IonLabel>
              <h3 style={{ color: "#737373", fontWeight: "normal" }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BUDDYTAPE
              </h3>
            </IonLabel>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton
              onClick={() => {
                openMenu();
              }}
            >
              <IonIcon color="primary" icon={ellipsisVertical}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonButtons>
          <IonSearchbar
            mode="ios"
            value={searchText}
            onIonChange={(e) => setSearchText(e.detail.value!)}
            slot="start"
            style={{ marginLeft: "13px" }}
          ></IonSearchbar>
          <IonButton slot="end" fill="clear" color="medium" style={{ marginRight: "25px" }} onClick={e => handleFilterOptions(e)}>
            <IonIcon icon={filter} />
          </IonButton>
        </IonButtons>
        <IonRow style={{ marginLeft: "20px" }}>
          {searchTags && searchTags.map((tag: Tag) => (
            <IonChip key={tag.name} style={setTagColor(tag.color)} onClick={(e) => removeSearchTag(tag)}>
              <IonIcon icon={closeCircle} />
              <IonLabel style={{ color: "#566573", fontSize: "smaller", marginTop: "3px" }}>{tag.name}</IonLabel>
            </IonChip>
          ))}
        </IonRow>
      </IonHeader>
      <IonContent scrollY={true}>
        <IonContent>
          <div>
            <IonRefresher
              slot="fixed"
              onIonRefresh={doRefresh}
              disabled={false}
              pullFactor={0.7}
              pullMin={150}
              pullMax={200}
              style={{ zIndex: "101" }}
            >
              <IonRefresherContent
                pullingIcon="arrow-dropdown"
                pullingText="Pull to Refresh"
                refreshingSpinner="circles"
                refreshingText="Refreshing..."
              ></IonRefresherContent>
            </IonRefresher>
            <div>
              <IonList
                lines="none"
                style={{
                  paddingTop: "0vh",
                  paddingBottom: "10px",
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                }}
              >
                {/* User has no systems created */}
                {userLibrary.length === 0 && (
                  <div style={{ paddingTop: "4vh" }}>
                    <IonCard className="system-item" button={false}>
                      <IonCardHeader></IonCardHeader>
                      <IonCardContent style={{ textAlign: "center" }}>
                        <br />
                        <IonCardSubtitle>
                          Use the blue at the bottom to create your first
                          system! 🍍
                        </IonCardSubtitle>
                        <br />
                        <IonCardSubtitle>
                          For detailed instructions, visit the{" "}
                          <IonLabel
                            color="danger"
                            onClick={() => history.push("/intro")}
                          >
                            tutorial
                          </IonLabel>
                          .
                        </IonCardSubtitle>
                        <br />
                      </IonCardContent>
                    </IonCard>
                  </div>
                )}
                {userLibrary
                  .filter((system: System) => {
                    const systemName =
                      system.elements[0].metadata.name.toLowerCase();
                    if (searchText.length < 1) {
                      return true;
                    } else if (
                      systemName.indexOf(searchText.toLowerCase()) !== -1
                    ) {
                      return true;
                    } else if (
                      system.elements[0].metadata.description
                        .toLowerCase()
                        .indexOf(searchText.toLowerCase()) !== -1
                    ) {
                      return true;
                    } else {
                      return false;
                    }
                  })
                  // filter on search tags
                  .filter((system: System) => {
                    if (searchTags.length > 0) {
                      // filter against tags
                      if (system.tags && system.tags.length > 0) {
                        return system.tags.some((t: Tag) => searchTags.map((t: Tag) => t.name).includes(t.name));
                      } else {
                        let containsUntagged = false;
                        // check if it is untagged
                        for (let i = 0; i < searchTags.length; i++) {
                          if (searchTags[i].name === UntaggedTag.name) {
                            containsUntagged = true;
                            return true;
                          }
                        }
                        return false;
                      }
                    }
                    return true;
                  })
                  .sort((a, b) => (a.modifiedTime < b.modifiedTime ? 1 : -1))
                  .map((system: System) => (
                    <IonItem key={system.systemId} color="none">
                      <IonCard
                        onClick={() => {
                          setActiveSystemId(system.systemId)
                          handleOpenDrawer();
                        }}
                        className="system-item"
                        button={true}
                      >
                        <IonCardContent>
                          <IonGrid>
                            <IonRow text-center>
                              <IonCol
                                className="ion-text-start"
                                style={{ marginLeft: "-5px" }}
                              >
                                <IonCardTitle style={{ fontSize: "1.1em" }}>
                                  {system.elements[0].metadata.name}
                                </IonCardTitle>
                              </IonCol>
                            </IonRow>
                            <IonRow
                              className="ion-text-start"
                              style={{ paddingRight: "1vh" }}
                            >
                              <p>
                                {formatDescription(
                                  system.elements[0].metadata.description
                                )}
                              </p>
                            </IonRow>
                          </IonGrid>
                        </IonCardContent>
                      </IonCard>
                    </IonItem>
                  ))}
              </IonList>
            </div>
          </div>
        </IonContent>
      </IonContent>
      <LibrarySystemSheet
        isOpen={showBottomSheet}
        close={handleCloseDrawer}
        systemId={activeSystemId}
      />
      <IonFab vertical="bottom" horizontal="center" slot="fixed">
        <IonFabButton
          onClick={() => setCreateSystemAlert(true)}
          color="secondary"
        >
          <IonIcon icon={add} />
        </IonFabButton>
      </IonFab>
      <IonFooter className="ion-no-border" color="light">
        <IonToolbar></IonToolbar>
      </IonFooter>
      <IonAlert
        isOpen={createSystemAlert}
        onDidDismiss={() => setCreateSystemAlert(false)}
        cssClass="create-system-alert"
        header={"Create a System"}
        message={"What would you like to name your system?"}
        inputs={[
          {
            name: "title",
            type: "text",
            placeholder: "Untitled System",
          },
        ]}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            handler: () => { },
          },
          {
            text: "Create",
            handler: (data: any) => {
              createSystem(data.title);
            },
          },
        ]}
      ></IonAlert>
      {/* Filter Options Menu Popover */}
      <IonPopover
        event={filterOptionsPopoverState.event}
        isOpen={filterOptionsPopoverState.showPopover}
        backdropDismiss={true}
        showBackdrop={true}
        onDidDismiss={() =>
          setFilterOptionsPopoverState({ showPopover: false, event: undefined })
        }
        side="end"
        alignment="center"
      >
        <IonContent>
          <IonList>
            {allTags.filter((tag: Tag) => showTagInFilters(tag)).sort((a, b) => sortTags(a.name, b.name)).map((tag: Tag) => (
              <IonItem
                key={tag.name}
                lines="none"
                button={true}
                detail={false}
                onClick={(e) => {
                  dismissFilterOptionsPopover(e);
                  handleFilterTagSelect(tag);
                }}>
                <div className="color-box" style={setColor(tag.color)}></div>
                <IonLabel style={{ fontSize: "smaller" }}>{tag.name}</IonLabel>
              </IonItem>
            ))}
          </IonList>
        </IonContent>
      </IonPopover>
      <IonToast
        isOpen={toastNotification.show}
        onDidDismiss={() => hideToastNotification()}
        icon={informationCircle}
        message={toastNotification.message}
        color={toastNotification.color}
        duration={3000}
      />
    </IonPage>
  );
};

export default Library;
