import { System, Tag, TagReference, UntaggedTag } from "../types/SystemTypes";

export function getTags(systems: Array<System>, removeDuplicates: boolean = false): Array<Tag> {
  const tags: Array<Tag> = [UntaggedTag];
  const tracker: Set<string> = new Set([]);

  for (let i = 0; i < systems.length; i++) {
    const system: System = systems[i];

    if (system.tags !== null && system.tags !== undefined) {
      for (let k = 0; k < system.tags.length; k++) {
        const tagVal: Tag = {
          color: system.tags[k].color,
          name: system.tags[k].name,
        }

        if (removeDuplicates && tracker.has(tagVal.name)) {
          continue
        } else {
          tracker.add(tagVal.name);
          tags.push(tagVal);
        }
      }
    }
  }

  return tags;
}

export function groupTags(tags: Array<Tag>): TagReference {
  if (tags === null || tags === undefined) {
    return {};
  }

  const tagReferences: TagReference = {};
  for (let i = 0; i < tags.length; i++) {
    tagReferences[tags[i].name] = tags[i];
  }
  return tagReferences;
}

export function setColor(color: string) {
  // Helps set CSS in style={{}} blocks
  return {
    backgroundColor: color,
  }
}


export const sortTags = (first: string, second: string): number => {
  // Sort alphabetically but put Untagged at the very end
  if (first === "Untagged") {
    return 1;
  } else if (second === "Untagged") {
    return -1;
  } else {
    return String(first).localeCompare(second);
  }
}
