import { IonAlert, IonToast } from "@ionic/react";
import copy from "copy-to-clipboard";
import { useState } from "react";

const ShareModal = ({ showShareAlert, setShowShareAlert, systemId }: any) => {
  const [showToast, setShowToast] = useState(false);

  return (
    <>
      <IonAlert
        isOpen={showShareAlert}
        onDidDismiss={() => setShowShareAlert(false)}
        header={"🍍 Share Your System 🍍"}
        message={"Note: Viewers can only view and not modify your system."}
        inputs={[
          {
            name: "shareUrl",
            value: `https://buddytape.app/s/${systemId}`,
            type: "url",
          },
        ]}
        buttons={[
          {
            text: "Copy to Clipboard",
            handler: () => {
              copy(`https://buddytape.app/s/${systemId}`);
              setShowToast(true);
            },
          },
          "Close",
        ]}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message="Share URL copied to clipboard!"
        duration={1000}
      />
    </>
  )
};

export default ShareModal;