export function isValidEmail(email: string): boolean {
  if (
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      email
    )
  ) {
    return true;
  }
  return false;
}

export function isValidPassword(password: string): boolean {
  const regex = /\d/g; // has at least one digit

  if (regex.test(password) && password.length >= 8) {
    return true;
  } else {
    return false;
  }
}
