import React, { Component } from "react";
import { isNode } from "react-flow-renderer";

// TODO: this doesn't go well here. Might end up with circular dependency
import { getLayoutedElements } from "../services/LayoutService";

import { Connection, NodeType, Event } from "../types/NodeTypes";
import { FirebaseStorageType } from "../types/ContextTypes";
import FirebaseService from "../services/FirebaseService";
import StorageService from "../services/StorageService";
import DataHandler from "../handlers/DataHandler";
import { System } from "../types/SystemTypes";

/*
 * Sources:
 * - https://www.savaslabs.com/blog/using-react-global-state-hooks-and-context
 * - https://www.taniarascia.com/using-context-api-in-react/
 */

const NodeContext = React.createContext(null);

export interface NodeProviderState {
  dataHandler: DataHandler;
  activeNodeId?: string;
  activeDocumentId?: string;
  elements: Array<NodeType>;
  readOnly: boolean;
}

// TODO: decouple raw data from additional styling

class NodeProvider extends Component<FirebaseStorageType, NodeProviderState> {
  dataHandler: DataHandler;
  private firebaseService: FirebaseService;
  private storageService: StorageService;

  constructor(props: FirebaseStorageType) {
    super(props);
    const { firebaseService, storageService } = props;

    this.firebaseService = firebaseService;
    this.storageService = storageService;
    this.dataHandler = new DataHandler(firebaseService, storageService);

    const initialState: NodeProviderState = {
      dataHandler: this.dataHandler,
      activeNodeId: null,
      activeDocumentId: null,
      elements: [],
      readOnly: true,
    };
    this.state = initialState;
  }

  setActiveNodeId = (nodeId?: string) => {
    this.setState({
      dataHandler: this.dataHandler,
      activeNodeId: nodeId,
      activeDocumentId: this.state.activeDocumentId,
      elements: this.state.elements,
      readOnly: this.state.readOnly,
    });
  };

  setElements = (elements: Array<NodeType>) => {
    this.setState({
      dataHandler: this.dataHandler,
      activeNodeId: this.state.activeNodeId,
      activeDocumentId: this.state.activeDocumentId,
      elements: elements,
      readOnly: this.state.readOnly,
    });
  };

  setActiveDocumentId = (documentId: string) => {
    this.setState({
      dataHandler: this.dataHandler,
      activeNodeId: this.state.activeNodeId,
      activeDocumentId: documentId,
      elements: this.state.elements,
      readOnly: this.state.readOnly,
    });
  };

  setReadOnly = (readOnly: boolean) => {
    this.setState({
      dataHandler: this.dataHandler,
      activeNodeId: this.state.activeNodeId,
      activeDocumentId: this.state.activeDocumentId,
      elements: this.state.elements,
      readOnly: readOnly,
    });
  };

  addNodes = (systemId: string, newNodes: Array<NodeType>) => {
    // ORIGINAL METHOD
    /*
    this.setState({
      activeNodeId: this.state.activeNodeId,
      elements: this.state.elements.concat(newNodes),
    });
    */

    // TODO: this should be wrapped in a service
    //this.orderElements();

    // TEMPORARY METHOD UNTIL SERVICE IS SET UP
    let updatedElements: Array<NodeType> = this.state.elements.concat(newNodes);

    // I can clean data here if I want to not store style info

    // HOOK INTO PERSISTANCE
    this.dataHandler.doGetSystem(systemId).then((system: System) => {
      let updatedSystem = system;
      updatedSystem.elements = updatedElements;
      this.dataHandler.doUpdateSystem(systemId, updatedSystem);
    });

    //this.dataHandler.doUpdateSystem()

    const layoutedElements = getLayoutedElements(updatedElements);
    this.setElements(layoutedElements);

    // console.log(layoutedElements);
  };

  removeNodes = (
    systemId: string,
    removeNodes: Array<string>,
    refresh = true
  ) => {
    // HACKY FOR MANUAL CONNECTION DELETION
    // eslint-disable-next-line array-callback-return
    let updatedElements = this.state.elements.filter(
      (v) => !removeNodes.includes(v.id)
    );

    if (refresh) {
      this.dataHandler.doGetSystem(systemId).then((system: System) => {
        let updatedSystem = system;
        updatedSystem.elements = updatedElements;
        this.dataHandler.doUpdateSystem(systemId, updatedSystem);
      });

      //this.dataHandler.doUpdateSystem()

      const layoutedElements = getLayoutedElements(updatedElements);
      this.setElements(layoutedElements);

      // console.log(layoutedElements);
    }
  };

  // Captures the node and edges to delete
  shouldNodeBeRemovedOnDelete(
    nodeIdToDelete: string,
    node: Event | Connection
  ): boolean {
    // Remove node to delete
    if (node.id === nodeIdToDelete) {
      return true;
    }
    // Remove connected edge
    if (!isNode(node)) {
      if (node.target === nodeIdToDelete) {
        return true;
      }
    }
    // Remove source edge (this is only applicable when the node is not a leaf node)
    if (!isNode(node)) {
      if (node.source === nodeIdToDelete) {
        return true;
      }
    }
    return false;
  }

  /*
  deleteNode = (nodeId: string) => {

  };
  */

  getNodeById = (nodeId: string): NodeType =>
    this.state.elements.find((x) => x.id === nodeId);

  getNumChildren = (nodeId: string): number =>
    this.state.elements.filter((x) => x.source === nodeId).length;

  hasChildren = (nodeId: string): boolean => this.getNumChildren(nodeId) > 0;

  hasManualConnection = (nodeId: string): boolean => {
    const manualConnections = this.state.elements.filter(
      (x) => x.source === nodeId && x.isCustom
    );
    if (manualConnections.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  /*
  orderElements = () => {
    this.setElements(getLayoutedElements(this.state.elements));
  };
  */

  render() {
    const { children } = this.props;
    const { activeNodeId, elements } = this.state;
    const {
      dataHandler,
      setActiveNodeId,
      setElements,
      addNodes,
      removeNodes,
      getNodeById,
      getNumChildren,
      hasChildren,
      hasManualConnection,
      shouldNodeBeRemovedOnDelete,
    } = this;

    return (
      <NodeContext.Provider
        value={{
          dataHandler,
          activeNodeId,
          elements,
          setActiveNodeId,
          setElements,
          addNodes,
          removeNodes,
          getNodeById,
          getNumChildren,
          hasChildren,
          hasManualConnection,
          shouldNodeBeRemovedOnDelete,
        }}
      >
        {children}
      </NodeContext.Provider>
    );
  }
}

export default NodeContext;

export { NodeProvider };
