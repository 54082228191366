import localForage from "localforage";

import { systemsConfig, userConfig } from "../configs/LocalForageConfigs";
import { System, UserLibrary } from "../types/SystemTypes";
import { User } from "../types/UserTypes";

export class StorageService {
  systemsStore: LocalForage;
  userStore: LocalForage;

  constructor() {
    this.systemsStore = localForage.createInstance(systemsConfig);
    this.userStore = localForage.createInstance(userConfig);
  }

  /*
   *  Systems Management
   */

  async getSystem(systemId: string): Promise<System | null> {
    /* Get a system */
    const library: UserLibrary = await this.systemsStore.getItem("library");
    return library.systems.find(
      (system: System) => system.systemId === systemId
    );
  }

  async setSystem(system: System, systemId?: string): Promise<System> {
    /* Create or update System */
    let library: UserLibrary = await this.systemsStore.getItem("library");

    // TODO: figure out best way to handle uninitialized store
    if (library === null) {
      return null;
    }

    // UPDATE
    if (systemId) {
      system.systemId = systemId; // make sure this is set
      system.modifiedTime = new Date().toISOString();

      const updateIndex: number = library.systems.findIndex(
        (system: System) => system.systemId === systemId
      );

      if (updateIndex !== undefined) {
        library.systems[updateIndex] = system;
      }
    }
    // CREATE
    else {
      library.systems.push(system);
    }

    return this.systemsStore
      .setItem("library", library)
      .then((userLibrary: UserLibrary) => {
        return system;
      });
  }

  async deleteSystem(systemId: string): Promise<void> {
    /* Delete a library */
    let library: UserLibrary = await this.systemsStore.getItem("library");

    library.systems = library.systems.filter(
      (system: System) => system.systemId !== systemId
    );

    await this.systemsStore.setItem("library", library);
  }

  /*
   *  Library Management
   */
  async setLibrary(library: UserLibrary): Promise<UserLibrary> {
    /* Set a library */
    return this.systemsStore.setItem("library", library);
  }

  async getLibrary(): Promise<UserLibrary | null> {
    /* Get a library */
    return await this.systemsStore.getItem("library");
  }

  /*
   *  Users Management
   */
  async getUser(): Promise<User | null> {
    return await this.userStore.getItem("user");
  }

  async setUser(user: User): Promise<User | null> {
    return await this.userStore.setItem("user", user);
  }

  async clearUser() {
    return await this.userStore.removeItem("user");
  }
}

export default StorageService;
