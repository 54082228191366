import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonToolbar,
} from "@ionic/react";
import { useContext, useEffect, useRef, useState } from "react";
import { RegistrationFields, FormError } from "../types/UserTypes";
import { isValidPassword, isValidEmail } from "../utils/UserUtils";
import AuthContext, { AuthProviderState } from "../context/AuthContext";
import SocialAuth from "../components/authentication/SocialAuth";
import { useHistory } from "react-router";
import { arrowBack } from "ionicons/icons";

const Register = () => {
  const { authHandler }: AuthProviderState = useContext(AuthContext);
  const history = useHistory();

  const [nameValue, setNameValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [confirmPasswordValue, setConfirmPasswordValue] = useState("");

  const nameField = useRef(null);
  const emailField = useRef(null);
  const passwordField = useRef(null);
  const confirmPasswordField = useRef(null);

  const [errorValue, setErrorValue] = useState<FormError>({
    hasError: true,
    message: "",
  });

  useEffect(() => {
    const unsubscribe = authHandler
      .getFirebaseService()
      .getFirebase()
      .auth()
      .onAuthStateChanged((authUser) => {
        if (authUser) {
          if (authUser.emailVerified) {
            history.replace("/library");
          } else {
            // TODO: toast not logged in
            // showNotVerifiedToast();
            authHandler.doSignOut();
          }
        } else {
          //history.replace("/landing");
        }
      });

    // Unmount subscriber
    return () => unsubscribe();
  }, [authHandler, history]);

  useEffect(() => {
    setNameValue("");
    setEmailValue("");
    setPasswordValue("");
    setConfirmPasswordValue("");
    setErrorValue({ hasError: true, message: "" });
  }, []);

  const handleSubmit = () => {
    // Synchronize form values if they were autocompleted
    if (nameValue !== nameField.current.value) {
      setNameValue(nameField.current.value);
    }
    if (emailValue !== emailField.current.value) {
      setEmailValue(emailField.current.value);
    }
    if (passwordValue !== passwordField.current.value) {
      setPasswordValue(passwordField.current.value);
    }
    if (confirmPasswordValue !== confirmPasswordField.current.value) {
      setConfirmPasswordValue(confirmPasswordField.current.value);
    }

    if (
      hasError({
        name: nameValue,
        password: passwordValue,
        confirmPassword: confirmPasswordValue,
        email: emailValue,
      })
    ) {
      // Invalid registration fields
    } else {
      // TODO: need to add more fields in here
      authHandler
        .doCreateUser({
          name: nameValue,
          email: emailValue,
          password: passwordValue,
        })
        .then((registrationError) => {
          setErrorValue(registrationError);
          // Log the user in after account has been created

          if (!registrationError.hasError) {
            // TODO: launch a toast!
            // authHandler.doSignInWithEmailAndPassword(emailValue, passwordValue);
          }
        });
    }
  };

  const hasError = (rf: RegistrationFields): boolean => {
    // Check for error conditions
    const errors: Array<string> = [];
    if (!isValidPassword(rf.password)) {
      errors.push(
        "Password must be at least 8 characters and contain at least 1 number."
      );
    }
    if (rf.password !== rf.confirmPassword) {
      errors.push("Passwords do not match.");
    }
    if (rf.name === undefined || rf.name.length < 1) {
      errors.push("Please provide a name.");
    }
    if (!isValidEmail(rf.email)) {
      errors.push("Email is not valid.");
    }

    // Set errors
    if (errors.length > 0) {
      setErrorValue({ hasError: true, message: errors.join("\n") });
      return true;
    } else {
      setErrorValue({ hasError: false, message: "" });
      return false;
    }
  };

  return (
    <IonPage>
      <IonContent>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonButtons slot="start">
              {/* TODO: Detect when this there is no history to go back to */}
              <IonButton color="none" onClick={() => history.goBack()}>
                <IonIcon color="btblue" icon={arrowBack}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonCard>
          <IonCardContent>
            <IonItem>
              <IonLabel position="stacked">Name</IonLabel>
              <IonInput
                type="text"
                value={nameValue}
                ref={(v) => (nameField.current = v)}
                onInput={(e) => {
                  setNameValue((e.target as HTMLInputElement).value);
                }}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Email Address</IonLabel>
              <IonInput
                type="text"
                value={emailValue}
                ref={(v) => (emailField.current = v)}
                onInput={(e) => {
                  setEmailValue((e.target as HTMLInputElement).value);
                }}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Password</IonLabel>
              <IonInput
                type="password"
                value={passwordValue}
                ref={(v) => (passwordField.current = v)}
                onInput={(e) => {
                  setPasswordValue((e.target as HTMLInputElement).value);
                }}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Confirm Password</IonLabel>
              <IonInput
                type="password"
                value={confirmPasswordValue}
                ref={(v) => (confirmPasswordField.current = v)}
                onInput={(e) => {
                  setConfirmPasswordValue((e.target as HTMLInputElement).value);
                }}
              ></IonInput>
            </IonItem>
            {errorValue.hasError && (
              <div className="registration-error">
                <IonLabel color="danger">{errorValue.message}</IonLabel>
              </div>
            )}
            <IonGrid style={{ width: "80%" }}>
              <IonRow className="ion-align-items-center">
                <IonCol className="ion-text-center">
                  <IonButton disabled={false} onClick={handleSubmit}>
                    Register
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardContent>
        </IonCard>
        <SocialAuth setErrorValue={setErrorValue}></SocialAuth>
      </IonContent>
    </IonPage>
  );
};

export default Register;
