import React, { Component } from "react";
import AuthHandler from "../handlers/AuthHandler";
import FirebaseService from "../services/FirebaseService";
import StorageService from "../services/StorageService";
import { FirebaseStorageType } from "../types/ContextTypes";
import { User } from "../types/UserTypes";

const AuthContext = React.createContext(null);

export interface AuthProviderState {
  authHandler: AuthHandler;
  isLoggedIn: boolean;
  user: User;
}

class AuthProvider extends Component<FirebaseStorageType, AuthProviderState> {
  authHandler: AuthHandler;
  private firebaseService: FirebaseService;
  private storageService: StorageService;

  constructor(props: FirebaseStorageType) {
    super(props);
    const { firebaseService, storageService } = props;

    this.firebaseService = firebaseService;
    this.storageService = storageService;
    this.authHandler = new AuthHandler(firebaseService, storageService);

    const initialState: AuthProviderState = {
      authHandler: this.authHandler,
      isLoggedIn: false,
      user: null,
    };
    this.state = initialState;
  }

  componentDidMount = async () => {
    // DEFAULT
    const signedIn: boolean = await this.authHandler.isSignedIn();
    let user: User | null = null;

    if (signedIn) {
      user = await this.authHandler.doGetUser();
    }

    this.setState({
      authHandler: this.authHandler,
      isLoggedIn: signedIn,
      user: user,
    });

    // OBSERVABLE UPDATE
    this.firebaseService.auth.onAuthStateChanged((user: any) => {
      if (user && user.emailVerified) {
        // user is logged in
        this.setState({
          authHandler: this.authHandler,
          isLoggedIn: true,
          user: {
            displayName: user.displayName,
            email: user.email,
            uid: user.uid,
            photoUrl: user.photoUrl,
          },
        });
      } else {
        this.setState({
          authHandler: this.authHandler,
          isLoggedIn: false,
          user: null,
        });
      }
    });
  };

  render() {
    const { children } = this.props;
    const { authHandler, isLoggedIn, user } = this.state;

    return (
      <AuthContext.Provider
        value={{
          authHandler,
          isLoggedIn,
          user,
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  }
}

export default AuthContext;

export { AuthProvider };
