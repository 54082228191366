import { useContext, useEffect, useRef, useState } from "react";
import {
  IonBackdrop, // eslint-disable-line @typescript-eslint/no-unused-vars
  IonButton,
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonList,
  IonRadioGroup,
  IonLabel,
  IonItem,
  IonRadio,
  IonInput,
  IonCardHeader,
  IonCardSubtitle,
  IonCardContent,
  IonCard,
  IonToast,
  IonPopover,
  IonTextarea,
  IonText,
  IonIcon,
  IonRow,
  IonImg,
  IonAlert,
  IonProgressBar,
} from "@ionic/react";
import {
  buildConnectedNode,
  isValidVideoUrl,
  updateNode,
} from "../utils/NodeUtils";
import { triggerUpdate } from "../utils/FlowUtils";
import "./NodeCreation.css";
import NodeContext from "../context/NodeContext";
import { isNode } from "react-flow-renderer";
import { FormError } from "../types/UserTypes";
import AuthContext, { AuthProviderState } from "../context/AuthContext";
import { System, UserLibrary } from "../types/SystemTypes";
import { EventType, BTImage } from "../types/NodeTypes";
import { useHistory } from "react-router";
import { camera, close, closeCircle } from "ionicons/icons";
import MediaContext from "../context/MediaContext";

// TODO: Set up interface for props
const NodeCreation = ({ systemId, existingNode, nodeId }: any) => {
  const {
    dataHandler,
    activeNodeId,
    addNodes,
    setActiveNodeId,
  } = useContext(NodeContext);

  const { user }: AuthProviderState = useContext(AuthContext);
  const { uploadImageFile } = useContext(MediaContext);

  const history = useHistory();

  /*
   *  Form input values
   */
  const [nameValue, setNameValue] = useState<string>("");
  const [typeValue, setTypeValue] = useState<string>("action");
  const [descriptionValue, setDescriptionValue] = useState<string>("");
  const [videoValue, setVideoValue] = useState<string>("");
  const [videoTimestamp, setVideoTimestamp] = useState<string>("");
  const [toastMessage, setToastMessage] = useState<string>("");

  const [pendingImageUploadFileName, setPendingImageUploadFileName] = useState<string>("");
  const [imageDescription, setImageDescription] = useState<string>("");
  const [imageValues, setImageValues] = useState<Array<BTImage>>([]);
  const [imageDeletionAlert, setImageDeletionAlert] = useState({ isVisible: false });

  // Load the user's systems for filtering
  const [userLibrary, setUserLibrary] = useState<Array<System>>([]);
  const [linkedSystemId, setLinkedSystemId] = useState<string>("");

  const [updateInProgress, setUpdateInProgress] = useState<boolean>(false);

  useEffect(() => {
    setUpdateInProgress(false);

    // Populate form with existing data if not creation workflow
    if (!(existingNode === null || existingNode === undefined)) {
      // console.log(existingNode); for debugging
      setNameValue(existingNode.metadata.name);
      setTypeValue(existingNode.metadata.type);
      setDescriptionValue(existingNode.metadata.description);

      if (
        existingNode.metadata.video &&
        Object.keys(existingNode.metadata.video).length > 0
      ) {
        setVideoValue(existingNode.metadata.video.url);
        setVideoTimestamp(existingNode.metadata.video.timestamp);
      }

      if (existingNode.metadata.images && existingNode.metadata.images.length > 0) {
        setImageValues(existingNode.metadata.images);
      }
    }

    dataHandler.doGetUserLibrary(user.uid, true).then((v: UserLibrary) => {
      setUserLibrary(v.systems);
    });
    // eslint-disable-next-line
  }, [existingNode, user]);

  // Autosuggest Popover
  const [searchPopoverState, setSearchShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });
  const [searchValue, setSearchValue] = useState("");

  /*
   *  Display values
   */
  const submitText = nodeId === null ? "Create" : "Update";
  const closeText = nodeId === null ? "Cancel" : "Close";

  // DECOUPLE FLOW PROVIDER
  //const setFlowElements = useStoreActions((actions) => actions.setElements);
  //
  //const [showDeleteButton, setShowDeleteButton] = useState(true);
  const [showToast, setShowToast] = useState(false);
  //
  /*
   *  Form validation
   */
  // TODO: verify if this is still being used properly
  const [errorValue] = useState<FormError>({
    hasError: true,
    message: "",
  });

  const inputIsValid = (): boolean => {
    const errors: Array<string> = [];

    if (nameValue === "" && typeValue !== "system") {
      errors.push("The title cannot be blank.");
    }
    if (videoValue.trim().length > 0 && !isValidVideoUrl(videoValue)) {
      errors.push("Video URL is invalid.");
    }

    if (videoTimestamp !== null && videoTimestamp !== "") {
      const pattern = /^(?:2[0-3]|[01]?[0-9]):[0-5][0-9]:[0-5][0-9]$/;
      if (!videoTimestamp.match(pattern)) {
        errors.push("Video timestamp must be in hh:MM:ss format")
      }
    }

    // Set errors
    if (errors.length > 0) {
      // TODO: errorValue is outdated and replaced by toast
      // setErrorValue({ hasError: true, message: errors.join("\n") });
      setToastMessage(errors.join("\n"));
      setShowToast(true);
      return false;
    } else {
      // setErrorValue({ hasError: false, message: "" });
      setToastMessage("");
      return true;
    }
  };

  const handleSubmit = async () => {
    if (!inputIsValid()) {
      return;
    }

    setUpdateInProgress(true);
    let submitSuccess = true;

    let nodeIdToSelect = nodeId;

    // Handle dynamic data that are unique per type
    let data: any = {};
    if (typeValue === "system") {
      data = { linkedSystemId: linkedSystemId };
    } else {
      data = {};
    }

    if (videoValue.length > 0) {
      data["video"] = {
        url: videoValue,
        timestamp: videoTimestamp,
      };
    } else {
      data["video"] = {};
    }

    // Image has to be uploaded before this value is set

    await handleImageUpload();
    if (pendingImageUploadFileName.length > 0) {
      data["images"] = imageValues;
    }

    if (nodeId) {
      // Node already exists. Update its values


      updateNode({
        nodeData: existingNode,
        name: nameValue,
        type: typeValue,
        description: descriptionValue,
        data: data,
      });


      triggerUpdate(); // Hack to click outside the current element
      addNodes(systemId, []);
      // TODO: manually update RF instance as well to synchronize data so color is updated
      // DECOUPLE FLOW PROVIDER
      //setFlowElements(elements);
    } else {
      // Create a new node
      const newNodes = buildConnectedNode(
        activeNodeId,
        nameValue,
        typeValue,
        descriptionValue,
        data
      );

      // Extract the ID from the node that we are about to create
      const newNode = newNodes.filter(isNode)[0];

      addNodes(systemId, newNodes);

      nodeIdToSelect = newNode.id;
    }

    if (submitSuccess) {
      //dismissModal();
      history.goBack();
      // race condition since this is async
      //setActiveNodeId(nodeId);
      setTimeout(() => {
        setActiveNodeId(nodeIdToSelect);
      }, 500);
    }

    setUpdateInProgress(false);
  };

  const formEndRef = useRef(null);

  const getTypeForm = () => {
    if (existingNode && existingNode.metadata.type === "title") {
      return <></>;
    } else {
      return (
        <IonCard className="creation-card">
          <IonCardHeader>
            <IonCardSubtitle>Type</IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
              <IonRadioGroup
                value={typeValue}
                onIonChange={(e) => {
                  setTypeValue(e.detail.value);
                }}
              >
                <IonItem onClick={() => setTypeValue("action")}>
                  <IonLabel>Action</IonLabel>
                  <IonRadio slot="start" value="action" />
                  <IonButton
                    className="color-button"
                    fill="solid"
                    slot="end"
                    color="btaction"
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </IonButton>
                </IonItem>
                <IonItem onClick={() => setTypeValue("reaction")}>
                  <IonLabel>Reaction (opponent)</IonLabel>
                  <IonRadio slot="start" value="reaction" />
                  <IonButton
                    className="color-button"
                    fill="solid"
                    slot="end"
                    color="btreaction"
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </IonButton>
                </IonItem>
                <IonItem onClick={() => setTypeValue("submission")}>
                  <IonLabel>Submission</IonLabel>
                  <IonRadio slot="start" value="submission" />
                  <IonButton
                    className="color-button"
                    fill="solid"
                    slot="end"
                    color="btsubmission"
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </IonButton>
                </IonItem>
                <IonItem onClick={() => setTypeValue("position")}>
                  <IonLabel>Position</IonLabel>
                  <IonRadio slot="start" value="position" />
                  <IonButton
                    className="color-button"
                    fill="solid"
                    slot="end"
                    color="btposition"
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </IonButton>
                </IonItem>
                <IonItem onClick={() => setTypeValue("system")}>
                  <IonLabel>System</IonLabel>
                  <IonRadio slot="start" value="system" />
                  <IonButton
                    className="color-button"
                    fill="solid"
                    slot="end"
                    color="btsystem"
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  </IonButton>
                </IonItem>
              </IonRadioGroup>
            </IonList>
          </IonCardContent>
        </IonCard>
      );
    }
  };

  const openFileSelect = () => {
    (document as any).getElementById("file-upload").click();
  }

  const onFileChange = (e: any) => {
    setPendingImageUploadFileName(e.target.files[0].name);
  }

  const isUploadImagePending = () => {
    if (pendingImageUploadFileName.length < 1) {
      return false;
    } else {
      return true;
    }
  }

  const handleImageUpload = (targetNodeId?: string) => {
    if (isUploadImagePending()) {
      setToastMessage("Uploading image. Please wait...");
      setShowToast(true);

      const imageFileInput: any = document.querySelector('input[type="file"]');
      const imageFile = imageFileInput.files[0];

      return uploadImageFile(imageFile, user.uid, systemId, targetNodeId).then((btImage: BTImage) => {
        // TODO: for now, we remove ability to update description on the image.

        let updatedImageValues: Array<BTImage> = imageValues;
        // Disable the last image and use the latest one
        if (updatedImageValues.length > 0) {
          updatedImageValues[updatedImageValues.length - 1].isActive = false;
        }

        if (imageDescription && imageDescription.length > 0) {
          btImage.description = imageDescription;
        }
        updatedImageValues.push(btImage);

        setImageValues(updatedImageValues);
      });
    }
  }

  const handleDeleteImage = () => {
    setImageDeletionAlert({
      isVisible: true,
    });
  }

  return (
    <IonPage>
      <IonHeader style={{ background: "white" }}>
        <IonToolbar>
          <IonTitle></IonTitle>
          <IonButtons slot="secondary">
            <IonButton disabled={updateInProgress} onClick={() => history.goBack()}>{closeText}</IonButton>
          </IonButtons>
          <IonButtons slot="primary">
            <IonButton disabled={updateInProgress} onClick={handleSubmit}>{submitText}</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      {updateInProgress &&
        <IonProgressBar type="indeterminate"></IonProgressBar>
      }
      <IonContent fullscreen={true} className="creation-content">
        {getTypeForm()}
        {/* Non-system specific fields */}
        {typeValue !== EventType.System && (
          <>
            <IonCard className="creation-card">
              <IonCardContent>
                <IonItem>
                  <IonInput
                    required
                    autocapitalize="on"
                    type="text"
                    placeholder="Title"
                    onInput={(e) => {
                      setNameValue((e.target as HTMLInputElement).value);
                    }}
                    //onSelect={() => setTimeout(() => formEndRef.current.scrollIntoView({ behavior: "smooth"}), 100)}
                    value={nameValue}
                  ></IonInput>
                </IonItem>
              </IonCardContent>
            </IonCard>
            <IonCard className="creation-card">
              <IonCardHeader>
                <IonCardSubtitle>
                  {nameValue}{" "}
                  {typeValue.charAt(0).toUpperCase() + typeValue.slice(1)}{" "}
                  <IonText style={{ float: "right", fontSize: "smaller" }}>
                    (optional)
                  </IonText>
                </IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
                <IonItem lines="inset">
                  <IonLabel position="floating">Notes</IonLabel>
                  <IonTextarea
                    autocapitalize="on"
                    onInput={(e) => {
                      setDescriptionValue((e.target as HTMLInputElement).value);
                    }}
                    value={descriptionValue}
                  ></IonTextarea>
                </IonItem>
                <IonItem lines="none" style={{ marginTop: "10px" }}>
                  {imageValues.filter((bti: BTImage) => bti.isActive).map((bti: BTImage) => (
                    <div key={bti.id}>
                      <IonImg src={bti.imageUrl} alt={nameValue + " image"} />
                      <IonButton size="small" color="danger" onClick={e => handleDeleteImage()}>Delete Image</IonButton>
                    </div>
                  ))}
                  {imageValues.filter((bti: BTImage) => bti.isActive).length < 1 &&
                    <IonItem style={{ marginLeft: "-15px", marginRight: "-20px" }} lines="none">
                      <input
                        type="file"
                        id="file-upload"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={(ev) => onFileChange(ev)}
                      />
                      <IonIcon icon={camera} onClick={openFileSelect} style={{ marginRight: "5px" }} />
                      <IonLabel style={{ fontSize: "smaller" }}>{pendingImageUploadFileName}</IonLabel>
                      <IonIcon hidden={!isUploadImagePending()} icon={closeCircle} onClick={() => setPendingImageUploadFileName("")} />
                    </IonItem>
                  }
                </IonItem>
                <div ref={formEndRef}></div>
                {errorValue.hasError && (
                  <div className="creation-error">
                    <IonLabel color="danger">{errorValue.message}</IonLabel>
                  </div>
                )}
              </IonCardContent>
            </IonCard>
          </>
        )}
        {/* System-Specific Content */}
        {typeValue === EventType.System && (
          <>
            <IonCard className="creation-card">
              <IonCardHeader>
                <IonLabel>
                  Select from one of your existing systems to link them
                  together.
                </IonLabel>
              </IonCardHeader>
              <IonCardContent>
                <IonItem>
                  <IonInput
                    required
                    autocapitalize="on"
                    type="text"
                    placeholder="System Name"
                    onClick={(e) => {
                      e.persist();
                      setSearchShowPopover({ showPopover: true, event: e });
                    }}
                    onInput={(e) => {
                      if (!searchPopoverState.showPopover) {
                        e.persist();
                        setSearchShowPopover({ showPopover: true, event: e });
                      }
                      const value = (e.target as HTMLInputElement).value;
                      setSearchValue(value);
                    }}
                    value={nameValue}
                  ></IonInput>
                </IonItem>
              </IonCardContent>
            </IonCard>
            <IonCard className="creation-card">
              <IonCardHeader>
                <IonCardSubtitle>
                  System Details{" "}
                  <IonText style={{ float: "right", fontSize: "smaller" }}>
                    (optional)
                  </IonText>
                </IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
                <IonItem>
                  <IonLabel position="floating">Description</IonLabel>
                  <IonTextarea
                    autocapitalize="on"
                    onInput={(e) => {
                      setDescriptionValue((e.target as HTMLInputElement).value);
                    }}
                    value={descriptionValue}
                  ></IonTextarea>
                </IonItem>
              </IonCardContent>
            </IonCard>
          </>
        )}
        {/* Video Content */}
        {typeValue !== EventType.System && (
          <IonCard className="creation-card">
            <IonCardHeader>
              <IonCardSubtitle>
                Reference Video{" "}
                <IonText style={{ float: "right", fontSize: "smaller" }}>
                  (optional)
                </IonText>
              </IonCardSubtitle>
              <IonLabel>YouTube URL and corresponding timestamp.</IonLabel>
            </IonCardHeader>
            <IonCardContent>
              <IonItem>
                <IonInput
                  className="video-url"
                  autocapitalize="off"
                  type="url"
                  onInput={(e) => {
                    setVideoValue((e.target as HTMLInputElement).value);
                  }}
                  value={videoValue}
                  placeholder="youtube.com/watch?v=VIDEOID"
                ></IonInput>
                <IonInput
                  className="video-picker"
                  slot="end"
                  value={videoTimestamp}
                  onInput={(e) => {
                    setVideoTimestamp((e.target as HTMLInputElement).value);
                  }}
                  type="text"
                  placeholder="00:00:00"
                ></IonInput>
              </IonItem>
            </IonCardContent>
          </IonCard>
        )}
      </IonContent>
      <IonPopover
        className="search-popover"
        event={searchPopoverState.event}
        isOpen={searchPopoverState.showPopover}
        onDidDismiss={() => {
          setSearchShowPopover({ showPopover: false, event: undefined });
        }}
        keyboardClose={false}
        showBackdrop={false}
      >
        <IonList>
          {userLibrary
            .filter((system: System) => {
              return system.name
                .toLowerCase()
                .includes(searchValue.toLowerCase());
            })
            .map((system: System) => {
              return (
                <IonItem
                  key={system.systemId}
                  onClick={() => {
                    setNameValue(system.name);
                    if (system.description) {
                      setDescriptionValue(system.description);
                    }
                    setSearchShowPopover({
                      showPopover: false,
                      event: undefined,
                    });
                    setLinkedSystemId(system.systemId);
                  }}
                >
                  {system.name}
                </IonItem>
              );
            })}
        </IonList>
      </IonPopover>
      {/* Generic toast */}
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => {
          setShowToast(false);
          setToastMessage("");
        }}
        color="medium"
        message={toastMessage}
        duration={4500}
      />
      <IonAlert
        isOpen={imageDeletionAlert.isVisible}
        onDidDismiss={() => {
          setImageDeletionAlert({
            isVisible: false,
          });
        }}
        header={`Are you sure you want to delete this image"?`}
        message={"Deletion is permanent and cannot be undone!"}
        buttons={[
          {
            text: "No",
            role: "cancel",
            cssClass: "secondary",
            handler: () => { },
          },
          {
            text: "Confirm",
            handler: (e) => {
              if (imageValues.length > 0) {
                setImageValues(imageValues.map((bti: BTImage) => {
                  bti.isActive = false;
                  return bti;
                }))
              }
            },
          },
        ]}
      />
    </IonPage>
  );
};

export default NodeCreation;
