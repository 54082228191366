import {
  IonBackdrop,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonPopover,
  IonToolbar,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";

import "./Main.css";
import FlowContainer from "../components/FlowContainer";
import { useHistory, useLocation, useParams } from "react-router";
import {
  arrowBack,
  close,
  home,
  optionsOutline,
  share,
} from "ionicons/icons";
import AuthContext, { AuthProviderState } from "../context/AuthContext";
import { ReactFlowProvider } from "react-flow-renderer";
import { BottomSheet } from "../components/BottomSheet";
import { System } from "../types/SystemTypes";
import NodeContext from "../context/NodeContext";
import ShareModal from "../components/ShareModal";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Main = () => {
  const [settingsPopoverState, setSettingsShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });
  const [showShareAlert, setShowShareAlert] = useState(false);

  const [showToolbarStart, setShowToolbarStart] = useState(false);
  const [viewOnly, setViewOnly] = useState(true);

  const { authHandler }: AuthProviderState = useContext(AuthContext);
  const { dataHandler } = useContext(NodeContext);

  const { id } = useParams<Record<string, string | undefined>>();
  const query = useQuery();
  const history = useHistory();
  const historySource = query.get("source");

  const location = useLocation();

  useEffect(() => {
    authHandler.isSignedIn().then((v) => {
      dataHandler.doGetSystem(id, false).then((system: System) => {
        if (system) {
          // Determine if user is the owner and set the system as read-only or not
          authHandler.doGetUser().then((user) => {
            if (!user) {
              setViewOnly(true);
            } else if (user.uid === system.ownerId) {
              setViewOnly(false);
            }
          });
        }
      });

      if (!v) {
        if (historySource === "landing") {
          setShowToolbarStart(true);
        } else {
          setShowToolbarStart(false);
        }
      } else {
        if (historySource === "library") {
          setShowToolbarStart(true);
        } else if (historySource === "system") {
          setShowToolbarStart(true);
        } else if (historySource === "landing") {
          setShowToolbarStart(true);
        } else {
          setShowToolbarStart(false);
        }
      }
    });
    // eslint-disable-next-line
  }, [authHandler, historySource]);

  /* Bottom sheet configurations */
  const [showBottomSheet, setShowBottomSheet] = useState(false);

  const handleCloseDrawer = () => {
    if (showBottomSheet) {
      setShowBottomSheet(false);
    }
  };

  const handleOpenDrawer = () => {
    if (!showBottomSheet) {
      setShowBottomSheet(true);
    }
  };

  const handleCloseSettingsPopover = () => {
    setSettingsShowPopover({ showPopover: false, event: undefined });
  }

  return (
    <>
      <IonPage>
        <IonHeader className="ion-no-border" style={{ position: "fixed" }}>
          <IonToolbar>
            {showToolbarStart && (
              <IonButtons slot="start">
                <IonButton
                  color="none"
                  onClick={() => {
                    handleCloseDrawer();
                    if (historySource === "library") {
                      history.replace("/library");
                    } else {
                      history.goBack();
                    }
                  }}
                >
                  <IonIcon color="btblue" icon={arrowBack}></IonIcon>
                </IonButton>
              </IonButtons>
            )}
            <IonButtons slot="end">
              <IonButton
                onClick={(e: any) => {
                  e.persist();
                  setSettingsShowPopover({ showPopover: true, event: e });
                }}
              >
                <IonIcon icon={optionsOutline}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <ReactFlowProvider>
            <FlowContainer
              systemId={id}
              source={historySource}
              openDrawer={handleOpenDrawer}
              closeDrawer={handleCloseDrawer}
            />
            {showBottomSheet && <IonBackdrop />}
            <BottomSheet
              isOpen={showBottomSheet}
              close={handleCloseDrawer}
              showAdd={!viewOnly}
              showEdit={!viewOnly}
              systemId={id}
            />
          </ReactFlowProvider>
        </IonContent>
        <ShareModal showShareAlert={showShareAlert} setShowShareAlert={setShowShareAlert} systemId={id} />
        <IonPopover
          event={settingsPopoverState.event}
          isOpen={settingsPopoverState.showPopover}
          onDidDismiss={() =>
            setSettingsShowPopover({ showPopover: false, event: undefined })
          }
        >
          <IonContent scrollY={false}>
            <IonList>
              <IonListHeader lines="inset">
                <IonLabel
                  style={{ fontSize: "medium", color: "#737373" }}
                  onClick={() => {
                    handleCloseDrawer();
                    handleCloseSettingsPopover();
                    history.replace("/library");
                  }}
                >
                  BUDDYTAPE
                </IonLabel>
              </IonListHeader>
              <IonItem
                lines="none"
                button={true}
                detail={false}
                onClick={() => {
                  handleCloseDrawer();
                  handleCloseSettingsPopover();
                  history.replace("/library");
                }}
              >
                <IonIcon icon={home} slot="start" size="small"></IonIcon>
                <IonLabel slot="start">Home</IonLabel>
              </IonItem>
              <IonItem
                lines="none"
                button={true}
                detail={false}
                onClick={() => setShowShareAlert(true)}
              >
                <IonIcon icon={share} slot="start" size="small"></IonIcon>
                <IonLabel slot="start">Share</IonLabel>
              </IonItem>
              <IonItem
                lines="none"
                button={true}
                detail={false}
                onClick={() =>
                  setSettingsShowPopover({
                    showPopover: false,
                    event: undefined,
                  })
                }
              >
                <IonIcon icon={close} slot="start" size="small"></IonIcon>
                <IonLabel slot="start">Close</IonLabel>
              </IonItem>
            </IonList>
          </IonContent>
        </IonPopover>
      </IonPage>
    </>
  );
};

export default Main;
