import FirebaseService from "../services/FirebaseService";
import StorageService from "../services/StorageService";
import { System, Tag, UserLibrary } from "../types/SystemTypes";

// TODO: set up FormError here and not inside of the service

class DataHandler {
  firebaseService: FirebaseService;
  storageService: StorageService;

  constructor(
    firebaseService?: FirebaseService,
    storageService?: StorageService
  ) {
    this.firebaseService = firebaseService;
    this.storageService = storageService;
  }

  doGetUserLibrary = async (
    userId: string,
    useCache: boolean = true
  ): Promise<UserLibrary> => {
    let library: UserLibrary | null;

    if (useCache) {
      library = await this.storageService.getLibrary();
    } else {
      library = null;
    }

    if (library) {
      return library;
    } else {
      const userLibrary: UserLibrary = await this.firebaseService.getUserLibrary(
        userId
      );
      this.storageService.setLibrary(userLibrary);
      return userLibrary;
    }
  };

  doGetSystem = async (
    systemId: string,
    getLatest: boolean = false
  ): Promise<System | null> => {
    if (!getLatest) {
      const library: UserLibrary | null = await this.storageService.getLibrary();
      if (library) {
        const system = library.systems.find((v) => v.systemId === systemId);
        if (system !== undefined) {
          return system;
        }
      }
    }
    return this.firebaseService.getSystem(systemId);
  };

  doAddSystem = async (system: System): Promise<System> => {
    return this.firebaseService.doAddSystem(system).then((systemId: String) => {
      return this.storageService.setSystem(system, systemId as string);
    });
  };

  doUpdateSystem = async (
    systemId: string,
    system: System
  ): Promise<System> => {
    this.firebaseService.doUpdateSystem(systemId, system);
    return await this.storageService.setSystem(system, systemId);
  };

  doDeleteSystem = async (systemId: string): Promise<void> => {
    this.firebaseService.doDeleteSystem(systemId);
    return this.storageService.deleteSystem(systemId);
  };
}

export default DataHandler;
