import {
  IonBackdrop, // eslint-disable-line @typescript-eslint/no-unused-vars
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonSearchbar,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import { addCircle, removeCircle } from "ionicons/icons";
import { useHistory, useParams } from "react-router";
import NodeContext from "../context/NodeContext";
import { isNode } from "react-flow-renderer";
import "./NodeConnections.css";
import { useContext, useEffect, useState } from "react";
import { ConnectedNodes, Connection, ConnectionType, Event, EventType } from "../types/NodeTypes";
import { buildConnectionWithTarget, getConnectedNodes } from "../utils/NodeUtils";

const NodeConnections = () => {
  const {
    addNodes,
    elements,
    getNodeById,
    removeNodes,
  } = useContext(NodeContext);

  const history = useHistory();
  const { systemId, nodeId } = useParams<Record<string, string | undefined>>();

  const existingNode = getNodeById(nodeId);

  const [searchText, setSearchText] = useState<string>("");

  // Track the connections on load and what has been added/removed to inputs to create a diff
  const [removedInputConnections, setRemovedInputConnections] = useState<Array<string>>([]);
  const [addedInputConnections, setAddedInputConnections] = useState<Array<string>>([]);
  const [inputConnectionValue, setInputConnectionValue] = useState<Array<Event>>([]); // mainly used to display

  // Track the connections on load and what has been added/removed to inputs to create a diff
  const [removedOutputConnections, setRemovedOutputConnections] = useState<Array<string>>([]);
  const [addedOutputConnections, setAddedOutputConnections] = useState<Array<string>>([]);
  const [outputConnectionValue, setOutputConnectionValue] = useState<Array<Event>>([]); // mainly used to display

  // connectionType === input / output
  const [nodeConnectSelectionModal, setNodeConnectSelectionModal] = useState({ isOpen: false, connectionType: undefined });

  useEffect(() => {
    // Populate form with existing data if not creation workflow
    if (existingNode === undefined) {
      // error condition
    }
    const connectedNodes: ConnectedNodes = getConnectedNodes(nodeId, elements);

    setInputConnectionValue(connectedNodes.inbound);
    setOutputConnectionValue(connectedNodes.outbound);

    // eslint-disable-next-line
  }, []);

  const dismissDrawer = () => { setNodeConnectSelectionModal({ isOpen: false, connectionType: undefined }) }

  const handleSubmit = () => {
    // Set new inbound connections
    let newInputEdges: Array<Connection> = [];
    if (addedInputConnections.length > 0) {
      newInputEdges = addedInputConnections.map(
        (v: string) => buildConnectionWithTarget(v, nodeId)[0]
      )
    }

    // Set new outbound connections
    let newOutputEdges: Array<Connection> = [];
    if (addedOutputConnections.length > 0) {
      newOutputEdges = addedOutputConnections.map(
        (v: string) => buildConnectionWithTarget(nodeId, v)[0]
      )
    }

    // Set the removed inbound connections
    let removedInputEdges: Array<string> = []
    if (removedInputConnections.length > 0) {
      removedInputEdges = removedInputConnections.map(
        (v: string) => `e_${v}_${nodeId}`
      )
    }

    // Set the removed outbound connections
    let removedOutputEdges: Array<string> = []
    if (removedOutputConnections.length > 0) {
      removedOutputEdges = removedOutputConnections.map(
        (v: string) => `e_${nodeId}_${v}`
      )

    }

    // Add all desired connections
    if (newOutputEdges.length > 0 || newInputEdges.length > 0) {
      const allNewEdges: Array<Connection> = newOutputEdges.concat(newInputEdges);
      addNodes(systemId, allNewEdges);
    }

    // Remove all desired connections
    if (removedOutputEdges.length > 0 || removedInputEdges.length > 0) {
      const allRemovedEdges: Array<string> = removedOutputEdges.concat(removedInputEdges);
      removeNodes(systemId, allRemovedEdges);
    }

    dismissDrawer();
    history.goBack();
  }

  const getColoredButton = (node: Event) => {
    let buttonColor;
    if (node.metadata.type === EventType.Title) {
      buttonColor = "light";
    } else {
      buttonColor = "bt" + node.metadata.type.toLowerCase();
    }

    return (
      <IonButton
        className="color-button"
        fill="solid"
        slot="end"
        color={buttonColor}
        style={{ fontWeight: "550" }}
      >
        &nbsp;{node.metadata.type[0].toUpperCase()}&nbsp;
      </IonButton>)
  }

  /* List of nodes that are valid connection */
  const getConnectionOptions = (connectionType: ConnectionType): Array<Event> => {
    if (connectionType === undefined) {
      return [];
    }

    return elements
      .filter(isNode)
      .filter((e: Event) => {
        // If there is a nodeId (meaning this is an edit and not a creation),
        // then make sure we don't allow the user to include the existing node!
        if (nodeId !== null && nodeId !== undefined) {
          if (e.id === nodeId) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      })
      .filter((e: Event) => {
        // Don't allow someone to point to a title node as output.

        // this only applies for setting outputs so we skip this check
        if (connectionType === ConnectionType.Input) {
          return true;
        }

        if (e.metadata.type === EventType.Title) {
          return false;
        } else {
          return true;
        }
      })
      .filter((e: Event) => {
        // Don't include nodes that have already been added.

        // Filter against input/output based on user option
        let existingConnections;
        if (connectionType === ConnectionType.Input) {
          existingConnections = inputConnectionValue;
        } else if (connectionType === ConnectionType.Output) {
          existingConnections = outputConnectionValue;
        }

        for (let i = 0; i < existingConnections.length; i++) {
          if (existingConnections[i].id === e.id) {
            return false;
          }
        }
        return true;
      })
      .sort((a: any, b: any) => a.metadata.name.toLowerCase() < b.metadata.name.toLowerCase());
  };

  const handleAddNodeSelection = (node: Event, connectionType: ConnectionType) => {
    if (connectionType === ConnectionType.Input) {
      setInputConnectionValue([...inputConnectionValue, node]);
      setAddedInputConnections([...addedInputConnections, node.id])
    } else if (connectionType === ConnectionType.Output) {
      setOutputConnectionValue([...outputConnectionValue, node]);
      setAddedOutputConnections([...addedOutputConnections, node.id])
    }
  }

  const handleRemoveNodeSelection = (node: Event, connectionType: ConnectionType) => {
    if (connectionType === ConnectionType.Input) {
      setInputConnectionValue(
        inputConnectionValue.filter((e) => e.id !== node.id)
      );
      setRemovedInputConnections(
        removedInputConnections.concat([node.id])
      );
      setAddedInputConnections(
        addedInputConnections.filter((k) => node.id !== k)
      );
    } else if (connectionType === ConnectionType.Output) {
      setOutputConnectionValue(
        outputConnectionValue.filter((e) => e.id !== node.id)
      );
      setRemovedOutputConnections(
        removedOutputConnections.concat([node.id])
      );
      setAddedOutputConnections(
        addedOutputConnections.filter((k) => node.id !== k)
      );
    }
  }

  return (
    <IonPage>
      <IonHeader style={{ background: "white" }}>
        <IonToolbar>
          <IonTitle></IonTitle>
          <IonButtons slot="secondary">
            <IonButton onClick={() => {
              dismissDrawer();
              history.goBack()
            }}>Cancel</IonButton>
          </IonButtons>
          <IonButtons slot="primary">
            <IonButton onClick={handleSubmit}>Update</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={true} scrollY={true} className="connection-content">
        {/* Don't display input connections for a title node */}
        {!(existingNode.metadata.type === "title") &&
          <IonCard className="connection-card">
            <IonCardHeader>
              <IonCardSubtitle>
                Input Connections
              </IonCardSubtitle>
              <IonLabel>
                Set the connections <b>entering</b> this node
              </IonLabel>
            </IonCardHeader>
            <IonCardContent>
              <IonList>
                {inputConnectionValue.map((v: Event) => {
                  return (
                    <IonItem
                      key={v.id}
                      onClick={() => {
                        handleRemoveNodeSelection(v, ConnectionType.Input);
                      }}
                      style={{ alignItems: "center" }}
                    >
                      <IonIcon icon={removeCircle} color="danger"></IonIcon>
                      <IonLabel>&nbsp;&nbsp;{v.metadata.name}</IonLabel>
                      {getColoredButton(v)}
                    </IonItem>
                  );
                })}
              </IonList>
              <IonItem
                lines="none"
                onClick={() => setNodeConnectSelectionModal({ isOpen: true, connectionType: "input" })}
                style={{ alignItems: "center" }}
              >
                <IonIcon icon={addCircle} color="secondary"></IonIcon>
                <IonLabel>&nbsp;&nbsp;Add input</IonLabel>
              </IonItem>
            </IonCardContent>
          </IonCard>
        }
        <IonCard className="connection-card">
          <IonCardHeader>
            <IonCardSubtitle>
              Output Connections
            </IonCardSubtitle>
            <IonLabel>
              Set the connections <b>exiting</b> this node
            </IonLabel>
          </IonCardHeader>
          <IonCardContent>
            <IonList>
              {outputConnectionValue.map((v: Event) => {
                return (
                  <IonItem
                    key={v.id}
                    onClick={() => {
                      handleRemoveNodeSelection(v, ConnectionType.Output)
                    }}
                    style={{ alignItems: "center" }}
                  >
                    <IonIcon icon={removeCircle} color="danger"></IonIcon>
                    <IonLabel>&nbsp;&nbsp;{v.metadata.name}</IonLabel>
                    {getColoredButton(v)}
                  </IonItem>
                );
              })}
            </IonList>
            <IonItem
              lines="none"
              onClick={() => setNodeConnectSelectionModal({ isOpen: true, connectionType: "output" })}
              style={{ alignItems: "center" }}
            >
              <IonIcon icon={addCircle} color="secondary"></IonIcon>
              <IonLabel>&nbsp;&nbsp;Add output</IonLabel>
            </IonItem>
          </IonCardContent>
        </IonCard>
      </IonContent>
      {/* Node Connection Settings */}
      <IonModal
        isOpen={nodeConnectSelectionModal.isOpen}
        onDidDismiss={() => dismissDrawer()}
        breakpoints={[0, 0.6, 1]}
        initialBreakpoint={0.6}
        swipeToClose={true}
      >
        <IonHeader translucent>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton onClick={() => dismissDrawer()}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonSearchbar
            mode="ios"
            value={searchText}
            onIonChange={(e) => setSearchText(e.detail.value!)}
          ></IonSearchbar>
          <IonList>
            {getConnectionOptions(nodeConnectSelectionModal.connectionType)
              .filter((e: Event) => {
                const searchQuery = searchText.toLowerCase();
                const nodeName = e.metadata.name.toLowerCase();
                if (searchText.length < 1) {
                  return true;
                } else if (nodeName.indexOf(searchQuery) !== -1) {
                  return true;
                } else if (e.metadata.description.toLowerCase().indexOf(searchQuery) !== -1) {
                  return true;
                } else if (e.metadata.type.toLowerCase().indexOf(searchQuery) !== -1) {
                  return true;
                } else {
                  return false;
                }
              })
              .sort((a, b) => (a.metadata.name < b.metadata.name ? 1 : -1))
              .map((v: Event) => {
                return (
                  <IonItem key={v.id} onClick={() => {
                    handleAddNodeSelection(v, nodeConnectSelectionModal.connectionType);
                    dismissDrawer();
                  }}>
                    {getColoredButton(v)}
                    <IonLabel><h2>{v.metadata.name}</h2><p>{v.metadata.description}</p></IonLabel>
                  </IonItem>
                )
              })}
          </IonList>
        </IonContent>
      </IonModal>
    </IonPage>
  )
}

export default NodeConnections;