import { useContext } from "react";
import ReactPlayer from "react-player/youtube";
import {
  IonCardHeader,
  IonCardSubtitle,
  IonCardContent,
  IonCard,
  IonCardTitle,
  IonButton,
  IonRow,
  IonChip,
  IonIcon,
  IonLabel,
  IonGrid,
  IonCol,
  IonImg,
  IonContent,
} from "@ionic/react";
import NodeContext from "../context/NodeContext";
import "./NodeDetail.css";
import { useHistory } from "react-router";
import { BTImage, Video } from "../types/NodeTypes";
import { attachTimestamp } from "../utils/NodeUtils";
import { add, ellipsisHorizontal } from "ionicons/icons";

const NodeDetail = ({ showOptions, showAdd, handleOptions, handleCreate }: any) => {
  const { activeNodeId, getNodeById } = useContext(NodeContext);
  const history = useHistory();

  const getTitle = (nodeId: string) => {
    const node = getNodeById(nodeId);
    if (node === null || node === undefined) {
      return "";
    } else {
      return node.metadata.name;
    }
  };

  const getDescription = (nodeId: string) => {
    const node = getNodeById(nodeId);
    if (node === null || node === undefined) {
      return "";
    } else {
      return node.metadata.description;
    }
  };

  const getLinkedSystemId = (nodeId: string) => {
    const node = getNodeById(nodeId);
    if (node === null || node === undefined) {
      return undefined;
    } else if (node.metadata.type === "system") {
      return node.metadata.linkedSystemId;
    } else {
      return undefined;
    }
  };

  const getType = (nodeId: string) => {
    const node = getNodeById(nodeId);
    if (node === null || node === undefined) {
      return "";
    } else if (node.metadata.type === "reaction") {
      // manually handle display text for reaction
      return "reaction (opponent)";
    } else if (node.metadata.type === "title") {
      // don't display title node name
      return "";
    } else {
      return node.metadata.type;
    }
  };

  const getVideoData = (nodeId: string): Video => {
    const node = getNodeById(nodeId);
    if (!node) {
      return null;
    } else {
      if (node.metadata.video) {
        return node.metadata.video;
      } else {
        return null;
      }
    }
  };

  // Return HTML for description card
  const getDescriptionElement = (nodeId: string) => {
    const descriptionValue = getDescription(nodeId);

    if (descriptionValue.length > 0) {
      return (
        <IonCard className="detail-item">
          <IonCardContent>
            <div className="display-linebreak">
              {getDescription(activeNodeId)}
            </div>
          </IonCardContent>
        </IonCard>
      );
    } else {
      return <></>;
    }
  };

  // Return HTML for linked system details
  const getLinkedSystemElement = (nodeId: string) => {
    const linkedSystemId: string = getLinkedSystemId(nodeId);

    if (linkedSystemId) {
      return (
        <IonCardContent>
          <IonButton
            color="secondary"
            onClick={() => {
              history.push(`/s/${linkedSystemId}?source=system`);
            }}
          >
            View System
          </IonButton>
        </IonCardContent>
      );
    } else {
      return <></>;
    }
  };

  // Return HTML for video details
  const getVideoElement = (nodeId: string) => {
    const videoData: Video = getVideoData(nodeId);
    if (videoData && videoData.url !== undefined) {
      const videoUrlWithTimestamp: string = attachTimestamp(videoData);
      return (
        <IonCardContent>
          <ReactPlayer
            key={nodeId}
            url={`${videoUrlWithTimestamp}`}
            width="100%"
            height="23em"
            controls={true}
            light={false}
          />
        </IonCardContent>
      );
    } else {
      return <></>;
    }
  };

  const getImageElement = (nodeId: string) => {
    const node = getNodeById(nodeId);
    if (node && node.metadata && node.metadata.images && node.metadata.images.filter((bti: BTImage) => bti.isActive).length > 0) {
      const imageData = node.metadata.images.filter((bti: BTImage) => bti.isActive)[0]

      return (
        <IonCardContent>
          <IonImg src={imageData.imageUrl} alt={node.metadata.name + " image."} />
        </IonCardContent>
      )
    } else {
      return (
        <></>
      )
    }
  }

  return (
    <>
      <IonCardHeader>
        <IonCardTitle>{getTitle(activeNodeId)}</IonCardTitle>
        <IonCardSubtitle>{getType(activeNodeId)}</IonCardSubtitle>

      </IonCardHeader>
      <IonRow class="ion-align-items-center">
        {showAdd && (
          <IonCol size="8">
            <IonChip className="large wide" color="primary" onClick={(e) => handleCreate(e)}>
              <IonIcon icon={add}></IonIcon>
              <IonLabel>Create Connection</IonLabel>
            </IonChip>
          </IonCol>
        )}
        <IonCol size="4">
          {showOptions && (
            <IonChip className="large wide" color="medium" onClick={(e) => handleOptions(e)}>
              <IonIcon className="ion-text-center" icon={ellipsisHorizontal} style={{ marginLeft: "-1px" }}></IonIcon>
            </IonChip>
          )}
        </IonCol>
      </IonRow>
      <IonContent scrollY={true}>
        <IonGrid style={{ marginTop: "-15px" }}>
          {getLinkedSystemElement(activeNodeId)}
          {getDescriptionElement(activeNodeId)}
          {getVideoElement(activeNodeId)}
          {getImageElement(activeNodeId)}
        </IonGrid>
      </IonContent>
    </>
  );
};

export default NodeDetail;
