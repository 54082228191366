import {
  IonAlert,
  IonBackdrop, // eslint-disable-line @typescript-eslint/no-unused-vars
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import {
  arrowBack,
  informationCircleOutline,
  logoDiscord,
  logoInstagram,
  logOutOutline,
  refreshOutline,
} from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { discordInviteUrl, instagramUrl } from "../constants/ExternalUrls";
import AuthContext, { AuthProviderState } from "../context/AuthContext";
import { User } from "../types/UserTypes";

const Settings = () => {
  const history = useHistory();

  const { authHandler }: AuthProviderState = useContext(AuthContext);

  const [email, setEmail] = useState("");

  const [showLogoutAlert, setShowLogoutAlert] = useState(false);
  const [showConfirmPasswordReset, setShowConfirmPasswordReset] =
    useState(false);
  const [showPasswordResetToast, setShowPasswordResetToast] = useState(false);

  useEffect(() => {
    const unsubscribe = authHandler
      .getFirebaseService()
      .getFirebase()
      .auth()
      .onAuthStateChanged((authUser) => {
        if (authUser) {
          setEmail(authUser.email);
        } else {
          history.replace("/landing");
        }
      });

    // Unmount subscriber
    return () => unsubscribe();
  }, [authHandler, history]);

  const handleLogOut = () => {
    authHandler.doSignOut();
  };

  const handleResetPassword = () => {
    authHandler.doGetUser().then((user: User) => {
      authHandler.doPasswordReset(email);
    });
  };

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton color="none" onClick={() => history.goBack()}>
              <IonIcon color="btblue" icon={arrowBack}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem
            lines="none"
            onClick={() => window.open(instagramUrl, "_blank")}
          >
            <IonIcon icon={logoInstagram} color="primary"></IonIcon>
            <IonLabel>&nbsp;Instagram</IonLabel>
          </IonItem>
          <IonListHeader>Support</IonListHeader>
          <IonItem
            lines="none"
            onClick={() => {
              history.push("/intro");
            }}
            detail={false}
          >
            <IonIcon icon={informationCircleOutline} color="primary"></IonIcon>
            <IonLabel>&nbsp;Tutorial</IonLabel>
          </IonItem>
          <IonItem
            lines="none"
            onClick={() => window.open(discordInviteUrl, "_blank")}
          >
            <IonIcon icon={logoDiscord} color="primary"></IonIcon>
            <IonLabel>&nbsp;Discord Channel</IonLabel>
          </IonItem>
        </IonList>
        <IonList>
          <IonListHeader>Account</IonListHeader>
          {/*<IonItem>{user.email}</IonItem>*/}
          <IonItem
            lines="none"
            onClick={() => {
              setShowConfirmPasswordReset(true);
            }}
          >
            <IonIcon icon={refreshOutline} color="primary"></IonIcon>
            <IonLabel>&nbsp;Reset Password</IonLabel>
          </IonItem>
          <IonItem lines="none" onClick={() => setShowLogoutAlert(true)}>
            <IonIcon icon={logOutOutline} color="primary"></IonIcon>
            <IonLabel color="danger">&nbsp;Log Out</IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
      <IonAlert
        isOpen={showLogoutAlert}
        onDidDismiss={() => setShowLogoutAlert(false)}
        subHeader={"Sign out of BuddyTape?"}
        message={"Are you sure you want to sign out?"}
        buttons={[
          {
            text: "No",
            role: "cancel",
            cssClass: "secondary",
            handler: () => { },
          },
          {
            text: "Yes",
            handler: () => {
              handleLogOut();
            },
          },
        ]}
      />
      <IonAlert
        isOpen={showConfirmPasswordReset}
        onDidDismiss={() => setShowConfirmPasswordReset(false)}
        subHeader={"Reset Password?"}
        message={"We will email a secure form to reset your password."}
        buttons={[
          {
            text: "No",
            role: "cancel",
            cssClass: "secondary",
            handler: () => { },
          },
          {
            text: "Yes",
            handler: () => {
              handleResetPassword();
            },
          },
        ]}
      />
      <IonToast
        isOpen={showPasswordResetToast}
        onDidDismiss={() => setShowPasswordResetToast(false)}
        color="medium"
        message="Password reset form has been emailed."
        duration={4000}
      />
    </IonPage>
  );
};

export default Settings;
