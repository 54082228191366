import React, { Dispatch, useContext } from "react";
import {
  IonButton,
  IonCardSubtitle,
  IonCol,
  IonIcon,
  IonLabel,
  IonRow,
} from "@ionic/react";
import { logoGoogle } from "ionicons/icons";
import { FormError } from "../../types/UserTypes";
import AuthContext from "../../context/AuthContext";

interface SocialAuthInterface {
  setErrorValue: Dispatch<FormError>;
}

const SocialAuth = ({ setErrorValue }: SocialAuthInterface) => {
  const { authHandler } = useContext(AuthContext);

  const filterMessages = (fe: FormError): FormError => {
    // Modify FE if it has an error that we want to ignore
    if (fe.hasError) {
      if (
        fe.message ===
        "The popup has been closed by the user before finalizing the operation."
      ) {
        return {
          hasError: false,
          message: "",
        };
      }
    } else {
      return fe;
    }
  };

  const handleGoogleLogin = () => {
    authHandler.doGoogleSignIn().then((fe: FormError) => {
      fe = filterMessages(fe);
      setErrorValue(fe);
    });
  };

  return (
    <>
      <IonRow className="ion-align-items-center">
        <IonCol className="ion-text-center">
          <IonCardSubtitle>― or continue with ―</IonCardSubtitle>
        </IonCol>
      </IonRow>
      <IonRow className="ion-align-items-center">
        <IonCol className="ion-text-center">
          <IonButton
            className="login-button"
            disabled={false}
            onClick={handleGoogleLogin}
            style={{ "--background": "#d34836" }}
          >
            <IonIcon icon={logoGoogle}></IonIcon>
            <IonLabel>&nbsp;&nbsp;Google&nbsp;&nbsp;</IonLabel>
          </IonButton>
        </IonCol>
      </IonRow>
    </>
  );
};

export default SocialAuth;
