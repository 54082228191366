import { IonAccordion, IonAccordionGroup, IonContent, IonHeader, IonItem, IonLabel, IonList, IonMenu, IonNote, IonToolbar } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import AuthContext, { AuthProviderState } from "../context/AuthContext";
import NodeContext from "../context/NodeContext";
import SystemContext from "../context/SystemContext";
import { System, TaggedSystem, UserLibrary } from "../types/SystemTypes";
import { setColor, sortTags } from "../utils/SystemUtils";

import "./SideMenu.css";

{/* TODO: creating new system doesn't set one in the list unless page is refreshed */ }
const SideMenu = () => {

  const {
    dataHandler,
    elements,
  } = useContext(NodeContext);

  const { user }: AuthProviderState = useContext(AuthContext);
  const { searchTags, systemUpdated, markSystemUpdated, updateSearchTags, resetSearchTags } = useContext(SystemContext);

  const location = useLocation()
  const [showMenu, setShowMenu] = useState(false);
  const [userLibrary, setUserLibrary] = useState<Array<System>>([]);
  const [taggedLibrary, setTaggedLibrary] = useState<TaggedSystem>({ "Untagged": [] });
  const [tagColors, setTagColors] = useState<Record<string, string>>({});

  const history = useHistory();

  // Pulls from the local cache so we can refresh the data properly when top level system data is modified
  useEffect(() => {
    if (user !== null && location !== null && location.pathname === "/library") {
      // I give up for now...this should be pulling from the cache
      // TODO: fix cache so newly added items are detected properly from here
      dataHandler.doGetUserLibrary(user.uid, false).then((ul: UserLibrary) => {
        //console.log(ul);
        if (ul.systems) {
          setUserLibrary(ul.systems);
        }
      });
    }
  }, [elements, location, systemUpdated])

  // Set the menu only if it's on certain paths
  useEffect(() => {
    if (location !== null && location.pathname === "/library") {
      setShowMenu(true);
    } else {
      setShowMenu(false);
    }
  }, [location])

  useEffect(() => {
    if (user !== null) {
      dataHandler.doGetUserLibrary(user.uid, false).then((ul: UserLibrary) => {
        console.log(ul);
        if (ul.systems) {
          setUserLibrary(ul.systems);
        }
      });
    }
  }, [user])

  // Set the tags used in the menu when it's updated or changes
  useEffect(() => {
    const tagColorVals: Record<string, string> = { "Untagged": "#F4F5F8" };
    const tagLibrary: TaggedSystem = { "Untagged": [] };
    for (let i = 0; i < userLibrary.length; i++) {
      const currentSystem: System = userLibrary[i];

      if (currentSystem.tags !== null && currentSystem.tags !== undefined && currentSystem.tags.length > 0) {
        for (let k = 0; k < currentSystem.tags.length; k++) {
          const currentTag = currentSystem.tags[k].name;
          tagColorVals[currentTag] = currentSystem.tags[k].color;

          if (currentTag in tagLibrary) {
            tagLibrary[currentTag].push(currentSystem);
          } else {
            tagLibrary[currentTag] = [currentSystem];
          }
        }
      } else {
        tagLibrary["Untagged"].push(currentSystem);
      }
    }
    setTaggedLibrary(tagLibrary);
    setTagColors(tagColorVals);
  }, [userLibrary])

  return (
    <>
      <IonMenu side="start" menuId="sideMenu" contentId="main" disabled={!showMenu}>
        <IonContent>
          <IonHeader className="ion-no-border" color="light">
            <IonToolbar color="none">
              <IonLabel>
                <h3 style={{ color: "#737373", fontWeight: "normal", paddingLeft: "15px" }}>BUDDYTAPE
                </h3>
              </IonLabel>
            </IonToolbar>
          </IonHeader>
          <IonAccordionGroup multiple={true} value={[]}>
            {Object.keys(taggedLibrary).sort((a, b) => sortTags(a, b)).map((tagName: string) => (
              <IonAccordion value={tagName} key={tagName}>
                <IonItem className="accordion-header" slot="header">
                  <IonLabel className="accordion-title">
                    <div className="color-box" style={setColor(tagColors[tagName])}></div>{tagName} <IonNote>({taggedLibrary[tagName].length})</IonNote>
                  </IonLabel>
                </IonItem>
                <IonList slot="content" lines="none">
                  {taggedLibrary[tagName].map((system: System) => (
                    <IonItem className="tagged-item" key={tagName + "_" + system.systemId}>
                      <IonLabel onClick={() =>
                        history.push(`/s/${system.systemId}?source=library`)
                      }>{system.elements[0].metadata.name}</IonLabel>
                    </IonItem>
                  ))}
                </IonList>
              </IonAccordion>
            )
            )}
          </IonAccordionGroup>
        </IonContent>
      </IonMenu>
    </>
  )
}

export default SideMenu;