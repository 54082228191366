import {
  IonAlert,
  IonBackdrop, // eslint-disable-line @typescript-eslint/no-unused-vars
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPopover,
} from "@ionic/react";
import "./BottomSheet.css";
import { getLayoutedElements } from "../services/LayoutService";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import { closeCircle, create, gitCompare, trash } from "ionicons/icons";
import NodeDetail from "./NodeDetail";
import { useHistory } from "react-router";
import NodeContext from "../context/NodeContext";
import { isTitleNode } from "../utils/NodeUtils";
import { useContext, useState } from "react";

interface BottomSheetProps {
  isOpen: boolean;
  close: (
    event:
      | CustomEvent<OverlayEventDetail<any>>
      | React.MouseEvent<any, MouseEvent>
  ) => void;
  showAdd: boolean;
  showEdit: boolean;
  systemId: string; // sloppy
}

export const BottomSheet: React.FC<BottomSheetProps> = ({
  isOpen,
  close,
  showAdd,
  showEdit,
  systemId,
}) => {
  const history = useHistory();
  const { removeNodes, setActiveNodeId, activeNodeId, setElements, elements, shouldNodeBeRemovedOnDelete, getNodeById, } = useContext(NodeContext);
  const [nodeOptionsPopoverState, setNodeOptionsPopoverState] = useState({ showPopover: false, event: undefined });
  const [showDeletionAlert, setShowDeletionAlert] = useState(false);

  const handleEdit = (e: any) => {
    close(e);
    history.push(`/s/${systemId}/node?id=${activeNodeId}`);
  };

  const handleOptions = (e: any) => {
    setNodeOptionsPopoverState({ showPopover: true, event: e });
  }

  const handleCreate = (e: any) => {
    close(e);
    history.push(`/s/${systemId}/node`);
  };

  const handleConnections = (e: any) => {
    close(e);
    history.push(`/s/${systemId}/node/${activeNodeId}/connections`);
  }

  const handleDeletion = (e: any) => {
    const updatedElements = getLayoutedElements(
      elements.filter((e: any) => !shouldNodeBeRemovedOnDelete(activeNodeId, e))
    );
    const removeElements = elements.filter((e: any) => !updatedElements.includes(e)).map((e: any) => e.id);

    removeNodes(systemId, removeElements, true);

    // Default to title node
    setActiveNodeId("n-0");

    // Refresh view
    setElements(updatedElements);

    // Dismiss popover
    dismissNodeOptionsPopover(e);

    // Close drawer
    close(e);
  }

  const dismissNodeOptionsPopover = (e: any) => {
    setNodeOptionsPopoverState({ showPopover: false, event: undefined })
  }

  return (
    <>
      <IonModal
        isOpen={isOpen}
        onDidDismiss={close}
        breakpoints={[0, 0.45, 0.8, 1.00]}
        initialBreakpoint={0.45}
        showBackdrop={false}
        backdropDismiss={true}
      >
        <NodeDetail showOptions={showEdit} showAdd={showAdd} handleOptions={handleOptions} handleCreate={handleCreate}></NodeDetail>
        <div
          className="bottom-sheet-close ion-padding-horizontal ion-text-end"
        >
          <IonIcon icon={closeCircle} color="medium" onClick={close} size="large"></IonIcon>
        </div>
      </IonModal>
      {/* Node Options Menu Popover */}
      <IonPopover
        event={nodeOptionsPopoverState.event}
        isOpen={nodeOptionsPopoverState.showPopover}
        backdropDismiss={true}
        showBackdrop={true}
        onDidDismiss={() =>
          setNodeOptionsPopoverState({ showPopover: false, event: undefined })
        }
        side="end"
        alignment="center"
      >
        <IonContent scrollY={true}>
          <IonList>
            <IonItem
              lines="none"
              button={true}
              detail={false}
              onClick={(e) => {
                dismissNodeOptionsPopover(e);
                handleEdit(e);
              }}
            >
              <IonLabel>Edit Details</IonLabel>
              <IonIcon slot="end" icon={create} size="small"></IonIcon>
            </IonItem>
            <IonItem
              lines="none"
              button={true}
              detail={false}
              onClick={(e) => {
                dismissNodeOptionsPopover(e);
                handleConnections(e);
              }}
            >
              <IonLabel>Modify Connections</IonLabel>
              <IonIcon slot="end" icon={gitCompare} size="small"></IonIcon>
            </IonItem>
            {!isTitleNode(activeNodeId, getNodeById) &&
              <IonItem
                lines="none"
                button={true}
                detail={false}
                onClick={(e) => {
                  dismissNodeOptionsPopover(e);
                  setShowDeletionAlert(true);
                }}
              >
                <IonLabel color="danger">Delete</IonLabel>
                <IonIcon slot="end" icon={trash} size="small" color="danger"></IonIcon>
              </IonItem>
            }
          </IonList>
        </IonContent>
      </IonPopover>
      {/* Deletion confirmation popup */}
      <IonAlert
        isOpen={showDeletionAlert}
        onDidDismiss={() => setShowDeletionAlert(false)}
        header={"Confirm Deletion"}
        message={"This can't be undone. Are you sure you want to delete this node?"}
        buttons={[
          {
            text: "No",
            role: "cancel",
            cssClass: "secondary",
            handler: () => { },
          },
          {
            text: "Yes",
            handler: (e) => {
              handleDeletion(e);
            },
          },
        ]}
      />
    </>
  );
};
