import React, { Component } from "react";
import { Tag } from "../types/SystemTypes";

const SystemContext = React.createContext(null);

export interface SystemProviderState {
  searchTags: Array<Tag>;
  systemUpdated: number; // increment this to trigger useEffect across different components
}

class SystemProvider extends Component<any, SystemProviderState> {

  constructor(props: any) {
    super(props);
    const initialState: SystemProviderState = {
      searchTags: [],
      systemUpdated: 0,
    };
    this.state = initialState;
  }

  markSystemUpdated = () => {
    this.setState({
      searchTags: this.state.searchTags,
      systemUpdated: this.state.systemUpdated + 1,
    });
  }

  addSearchTag = (tag: Tag) => {
    this.setState({
      searchTags: this.state.searchTags.concat([tag]),
      systemUpdated: this.state.systemUpdated,
    })
  }

  resetSearchTags = () => {
    this.setState({
      searchTags: [],
      systemUpdated: this.state.systemUpdated,
    })
  }

  removeSearchTag = (tag: Tag) => {
    this.setState({
      searchTags: this.state.searchTags.filter((t: Tag) => t.name !== tag.name),
      systemUpdated: this.state.systemUpdated,
    })
  }

  render() {
    const { children } = this.props;
    const { searchTags, systemUpdated } = this.state;
    const {
      markSystemUpdated,
      addSearchTag,
      resetSearchTags,
      removeSearchTag,
    } = this;

    return (
      <SystemContext.Provider
        value={{
          searchTags,
          systemUpdated,
          markSystemUpdated,
          addSearchTag,
          resetSearchTags,
          removeSearchTag,
        }}
      >
        {children}
      </SystemContext.Provider>
    );
  }
}

export default SystemContext;

export { SystemProvider };
