export const systemsConfig = {
  name: "BuddyTape",
  version: 1.0,
  storeName: "systems",
  description: "Data used to render the flow chart and library",
};

export const userConfig = {
  name: "BuddyTape",
  version: 1.0,
  storeName: "user",
  description: "Data used to persist user data",
};
