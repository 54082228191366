import {
  IonAlert,
  IonBackdrop, // eslint-disable-line @typescript-eslint/no-unused-vars
  IonButtons,
  IonCardContent,
  IonCardSubtitle,
  IonChip,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPopover,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "./LibrarySystemSheet.css";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import { closeCircle, ellipsisHorizontal, share, trash } from "ionicons/icons";
import NodeContext from "../context/NodeContext";
import { useContext, useEffect, useState } from "react";
import { System, Tag } from "../types/SystemTypes";
import { formatIsoDate } from "../utils/DateUtils";
import { useHistory } from "react-router";
import { setColor } from "../utils/SystemUtils";
import SystemContext from "../context/SystemContext";
import ShareModal from "./ShareModal";

interface LibrarySystemSheetProps {
  isOpen: boolean;
  close: (
    event:
      | CustomEvent<OverlayEventDetail<any>>
      | React.MouseEvent<any, MouseEvent>
  ) => void;
  systemId: string;
}

export const LibrarySystemSheet: React.FC<LibrarySystemSheetProps> = ({
  isOpen,
  close,
  systemId,
}) => {
  const { dataHandler, setElements, elements } = useContext(NodeContext);
  const { searchTags, systemUpdated, markSystemUpdated, updateSearchTags, resetSearchTags } = useContext(SystemContext);

  const history = useHistory();

  const [systemTags, setSystemTags] = useState<Array<Tag>>([]);
  const [systemName, setSystemName] = useState<string>("");
  const [systemDescription, setSystemDescription] = useState<string>("");
  const [systemCreationTime, setSystemCreationTime] = useState<string>("");

  const [systemOptionsPopoverState, setSystemOptionsPopoverState] = useState({ showPopover: false, event: undefined });

  const [showShareAlert, setShowShareAlert] = useState(false);
  const [deletionAlert, setDeletionAlert] = useState({
    isVisible: false,
    systemId: null,
    systemTitle: null,
  });


  useEffect(() => {
    if (systemId) {
      dataHandler.doGetSystem(systemId, false).then((system: System) => {
        if (system && system.tags) {
          setSystemTags(system.tags);
        } else {
          setSystemTags([]);
        }

        setSystemName(system.elements[0].metadata.name);
        setSystemDescription(system.elements[0].metadata.description);
        setSystemCreationTime(system.creationTime);
      });
    }
  }, [systemId, systemUpdated]);

  const handleEditSystem = (e: any) => {
    close(e);
    history.push(`/s/${systemId}?source=library`);
  }

  const dismissSystemOptionsPopover = (e: any) => {
    setSystemOptionsPopoverState({ showPopover: false, event: undefined })
  }

  const openSystemOptionsPopover = (e: any) => {
    setSystemOptionsPopoverState({ showPopover: true, event: e })
  }

  const handleDelete = () => {
    setDeletionAlert({
      isVisible: true,
      systemId: systemId,
      systemTitle: systemName,
    });
  };


  return (
    <>
      <IonModal
        isOpen={isOpen}
        onDidDismiss={close}
        breakpoints={[0, 0.60, 0.95]}
        initialBreakpoint={0.60}
        showBackdrop={true}
        backdropDismiss={true}
      >
        <IonContent scrollY={false}>
          <IonHeader>
            <IonToolbar>
              <IonTitle style={{ fontSize: "1.1em" }}>{systemName}</IonTitle>
              <IonButtons slot="end">
                <IonIcon icon={closeCircle} color="medium" onClick={close} size="large"></IonIcon>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonCardContent>
            <IonRow class="ion-align-items-center">
              <IonCol size="8">
                <IonChip className="large wide" color="primary" onClick={(e) => handleEditSystem(e)}>
                  <IonLabel style={{ fontWeight: "500" }}>View System</IonLabel>
                </IonChip>
              </IonCol>
              <IonCol size="4">
                <IonChip className="large wide" color="medium" onClick={(e) => openSystemOptionsPopover(e)}>
                  <IonIcon className="ion-text-center" icon={ellipsisHorizontal} style={{ marginLeft: "-1px" }}></IonIcon>
                </IonChip>
              </IonCol>
            </IonRow>
            <IonRow style={{ marginLeft: "10px", paddingTop: "1em" }}>
              <IonText style={{ fontSize: "smaller" }}>Created on {formatIsoDate(systemCreationTime, true)}</IonText>
            </IonRow>
            <IonRow style={{ marginLeft: "10px", paddingTop: "0.2em" }} className="display-linebreak">
              <IonCardSubtitle>{systemDescription}</IonCardSubtitle>
            </IonRow>
            <IonRow style={{ paddingTop: "2em" }}>
              {systemTags.map((tag: Tag) => (
                <IonChip key={tag.name} style={setColor(tag.color)}>
                  <IonLabel style={{ color: "#566573" }}>{tag.name}</IonLabel>
                </IonChip>
              ))}
              <IonChip outline={true} color="primary" onClick={(e) => {
                close(e);
                history.push(`/s/${systemId}/tags`);
              }}>
                <IonLabel>+ Add Tag</IonLabel>
              </IonChip>
            </IonRow>
          </IonCardContent>
        </IonContent>
        <div className="bottom-sheet-close ion-padding-horizontal ion-text-end" />
      </IonModal>
      <IonPopover
        event={systemOptionsPopoverState.event}
        isOpen={systemOptionsPopoverState.showPopover}
        backdropDismiss={true}
        showBackdrop={true}
        onDidDismiss={() =>
          setSystemOptionsPopoverState({ showPopover: false, event: undefined })
        }
        side="end"
        alignment="center"
      >
        <IonContent scrollY={false}>
          <IonList>
            <IonItem
              lines="none"
              button={true}
              detail={false}
              onClick={(e) => {
                dismissSystemOptionsPopover(e);
                setShowShareAlert(true);
              }}
            >
              <IonLabel>Share</IonLabel>
              <IonIcon slot="end" icon={share} size="small"></IonIcon>
            </IonItem>
            <IonItem
              lines="none"
              button={true}
              detail={false}
              onClick={(e) => {
                dismissSystemOptionsPopover(e);
                handleDelete();
              }}
            >
              <IonLabel color="danger">Delete</IonLabel>
              <IonIcon slot="end" icon={trash} size="small" color="danger"></IonIcon>
            </IonItem>
          </IonList>
        </IonContent>
      </IonPopover>
      <ShareModal showShareAlert={showShareAlert} setShowShareAlert={setShowShareAlert} systemId={systemId} />
      <IonAlert
        isOpen={deletionAlert.isVisible}
        onDidDismiss={() => {
          setDeletionAlert({
            isVisible: false,
            systemId: null,
            systemTitle: null,
          });
        }}
        header={`Are you sure you want to delete "${deletionAlert.systemTitle}"?`}
        message={"Deletion is permanent and cannot be undone!"}
        buttons={[
          {
            text: "No",
            role: "cancel",
            cssClass: "secondary",
            handler: () => { },
          },
          {
            text: "Confirm",
            handler: (e) => {
              console.log("Confirming deletion for " + deletionAlert.systemId);

              dataHandler.doDeleteSystem(deletionAlert.systemId).then(() => {
                markSystemUpdated();
                close(e);
              });
            },
          },
        ]}
      />
    </>
  );
};
