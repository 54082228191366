import React, { Component } from "react";
import CloudinaryService from "../services/CloudinaryService";
import { BTImage } from "../types/NodeTypes";

const MediaContext = React.createContext(null);

export interface MediaProviderState {
}

class MediaProvider extends Component<any, MediaProviderState> {

  cloudinaryService: CloudinaryService;

  constructor(props: any) {
    super(props);

    this.cloudinaryService = new CloudinaryService();

    const initialState: MediaProviderState = {
    };
    this.state = initialState;
  }

  uploadImageFile = async (imageFile: any, userId: string, systemId: string = undefined, nodeId: string = undefined): Promise<void | BTImage> => {
    return this.cloudinaryService.uploadImageFile(imageFile, userId, systemId, nodeId);
  }

  render() {
    const { children } = this.props;
    const { } = this.state;
    const {
      uploadImageFile,
    } = this;

    return (
      <MediaContext.Provider
        value={{
          uploadImageFile,
        }}
      >
        {children}
      </MediaContext.Provider>
    );
  }
}

export default MediaContext;

export { MediaProvider };
