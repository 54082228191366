const getMonthName = (month) => {
  const MONTH_NAMES = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "Jun",
    "07": "Jul",
    "08": "Aug",
    "09": "Sep",
    "10": "Oct",
    "11": "Nov",
    "12": "Dec",
  };
  return MONTH_NAMES[month];
};

export function formatIsoDate(date, showCurrentYear) {
  const dateOnly = date.split("T")[0];

  const dateVals = dateOnly.split("-");

  const year = dateVals[0];
  const month = dateVals[1];
  const day = dateVals[2];

  // Don't bother displaying the year for the current year
  const currentYear = new Date().getFullYear();
  const inputYear = parseInt(year);

  if (!showCurrentYear && inputYear === currentYear) {
    return `${getMonthName(month)} ${day}`;
  } else {
    return `${getMonthName(month)} ${day}, ${year}`;
  }
}
