import { Node, Edge } from "react-flow-renderer/dist/types";

export interface Event extends Node {
  metadata: EventMetadata;
}

export enum EventType {
  Submission = "submission",
  Position = "position",
  Reaction = "reaction",
  Action = "action",
  Title = "title", // Flag to set this as the root node
  System = "system",
}

export enum ConnectionType {
  Input = "input",
  Output = "output",
}

export interface Video {
  url: string;
  timestamp: string;
}

export interface BTImage {
  id: string; // maps to Cloudinary asset_id
  imageUrl: string;
  description?: string;
  creationTime: string;
  isActive: boolean;
  isDeleted: boolean; // if we need to programmatically catalog and purge unused assets
}

export interface EventMetadata {
  creationTime: string;
  modifiedTime: string;
  type: EventType;
  name: string;
  description: string;
  linkedSystemId?: string; // Only used for system types
  video?: Video;
  imageUrls?: Array<BTImage>;
}

export interface Connection extends Edge {
  isCustom?: boolean;
}

export type NodeType = Event & Connection;

export interface ConnectedNodes {
  inbound: Array<Event>;
  outbound: Array<Event>;
}
