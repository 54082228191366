const getConfig = () => {
  const env = process.env.NODE_ENV;

  if (env === "development") {
    return {
        apiKey: "AIzaSyBK9DM8nPjTJKbH98HW2g9hjgc7K8cMzUE",
        authDomain: "buddytape-staging.firebaseapp.com",
        projectId: "buddytape-staging",
        storageBucket: "buddytape-staging.appspot.com",
        messagingSenderId: "865916173446",
        appId: "1:865916173446:web:a0480d87dffc0cd8ad2507",
        measurementId: "G-KVSRS653ET"
    }
  } else {
      return {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
      }
  }
};

export default getConfig;
