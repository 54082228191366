import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonLabel,
  IonPage,
  IonRow,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import AuthContext, { AuthProviderState } from "../context/AuthContext";
import {
  construct,
  gitBranch,
  logoFacebook,
  logoInstagram,
  people,
  phonePortrait,
} from "ionicons/icons";
import { facebookUrl, instagramUrl } from "../constants/ExternalUrls";
import "./Landing.css";

const Landing = () => {
  const { authHandler }: AuthProviderState = useContext(AuthContext);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const history = useHistory();

  useEffect(() => {
    const unsubscribe = authHandler
      .getFirebaseService()
      .getFirebase()
      .auth()
      .onAuthStateChanged((authUser) => {
        if (authUser) {
          if (authUser.emailVerified) {
            history.replace("/library");
          } else {
            // TODO: toast not logged in
            showNotVerifiedToast();
            authHandler.doSignOut();
          }
        } else {
          //history.replace("/landing");
        }
      });

    // Unmount subscriber
    return () => unsubscribe();
  }, [authHandler, history]);

  const showNotVerifiedToast = () => {
    setToastMessage(
      "Please verify your account through our email-confirmation."
    );
    setShowToast(true);
  };

  return (
    <>
      <IonPage>
        <IonHeader className="ion-no-border landing-toolbar" color="light">
          <IonToolbar>
            <IonButtons slot="start">
              <IonLabel>
                <h3 style={{ color: "#737373", fontWeight: "normal" }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BUDDYTAPE
                </h3>
              </IonLabel>
            </IonButtons>
            <IonButton
              color="secondary"
              slot="end"
              onClick={() => {
                history.push("/login");
              }}
              style={{ paddingRight: "5px" }}
              size="small"
              fill="outline"
            >
              <IonLabel>
                <h5>Login</h5>
              </IonLabel>
            </IonButton>
            <IonButton
              color="primary"
              slot="end"
              onClick={() => {
                history.push("/register");
              }}
              style={{ paddingRight: "5px" }}
              size="small"
              fill="outline"
            >
              <IonLabel>
                <h5>Register</h5>
              </IonLabel>
            </IonButton>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen={true}>
          <div
            style={{
              background: `url(${process.env.PUBLIC_URL}/assets/img/banner-large.webp) no-repeat center top`,
              backgroundSize: "cover",
              width: "100%",
              height: "60%",
              alignItems: "center",
              justifyContent: "center",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                backgroundColor: "rgba(242, 245, 245, 0.3)",
                position: "relative",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
              }}
            >
              <IonGrid>
                <IonRow>
                  <IonCol style={{ textAlign: "center", paddingTop: "5em" }}>
                    <IonCard
                      style={{
                        margin: "0.9vh 0.9vh 0.9vh 0.9vh",
                        backgroundColor: "#f5f5f5",
                        boxShadow: "none",
                        opacity: "0.75",
                      }}
                    >
                      <IonCardContent>
                        <div>
                          <IonLabel
                            style={{
                              textTransform: "uppercase",
                              fontFamily: "Roboto",
                              fontWeight: "normal",
                              fontSize: "2.5vh",
                              letterSpacing: "1px",
                              lineHeight: "2.5vh",
                            }}
                          >
                            Progress your BJJ training by creating and sharing
                            flow charts
                          </IonLabel>
                        </div>
                        <div>
                          {/*
                          <IonLabel style={{ fontSize: "1.75vh" }}>
                            designed for mobile (and it's free!)
                          </IonLabel>*/}
                        </div>
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                </IonRow>
                <IonRow style={{ bottom: "10em" }}></IonRow>
                <IonRow>
                  <IonCol style={{ textAlign: "center" }}>
                    <IonButton
                      color="tertiary"
                      onClick={() => {
                        history.push("/register");
                      }}
                    >
                      Sign Up
                    </IonButton>
                    <IonButton
                      color="secondary"
                      onClick={() => {
                        history.push("/s/3VURAjEa?source=landing");
                      }}
                    >
                      Demo!
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </div>
          <IonCardContent>
            <div style={{ textAlign: "center" }}>
              <h1 style={{ fontWeight: "bolder" }}>Brazilian Jiu Jitsu Flow Chart Maker</h1>
              <br />
              <IonLabel>
                BuddyTape is an app that helps you take notes and plan your strategies with flow chart creation.
              </IonLabel>
              <br />
              <IonIcon icon={gitBranch}></IonIcon>
              <br />
              <br />
              <IonLabel>
                Create templates, reinforce training, annotate video
                instructionals, and more!
              </IonLabel>{" "}
              <br />
              <IonIcon icon={construct}></IonIcon>
              <br />
              <br />
              <IonLabel>
                Use it on your iOS/Android device or computer.
              </IonLabel>{" "}
              <br />
              <IonIcon icon={phonePortrait}></IonIcon> <br />
              <br />
              <IonLabel>
                BuddyTape was built for the BJJ community. So it's 100% free.
              </IonLabel>{" "}
              <br />
              <IonIcon icon={people}></IonIcon> <br />
            </div>
          </IonCardContent>
        </IonContent>

        <IonFooter className="ion-no-border">
          <IonToolbar>
            <div slot="start">
              <IonButton fill="clear" color="transparent">
                <IonIcon
                  icon={logoFacebook}
                  color="primary"
                  onClick={() => window.open(facebookUrl, "_blank")}
                ></IonIcon>
              </IonButton>
              <IonButton fill="clear" color="transparent">
                <IonIcon
                  icon={logoInstagram}
                  color="primary"
                  onClick={() => window.open(instagramUrl, "_blank")}
                ></IonIcon>
              </IonButton>
            </div>
            <IonLabel slot="end">
              <h5 style={{ fontWeight: "lighter" }}>
                &copy; 2020 - 2022 BUDDYTAPE &nbsp;&nbsp;&nbsp;
              </h5>
            </IonLabel>
          </IonToolbar>
        </IonFooter>
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => {
            setShowToast(false);
            setToastMessage("");
          }}
          color="secondary"
          message={toastMessage}
          duration={6000}
        />
      </IonPage>
    </>
  );
};

export default Landing;
