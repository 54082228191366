import { useContext } from "react";
import { useLocation, useParams } from "react-router";
import NodeCreation from "../pages/NodeCreation";
import NodeContext from "../context/NodeContext";

export const NodeWorkflow = () => {
  const { getNodeById } = useContext(NodeContext);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();

  const { systemId } = useParams<Record<string, string | undefined>>();
  const nodeId = query.get("id");

  return (
    <NodeCreation
      systemId={systemId}
      existingNode={getNodeById(nodeId)}
      nodeId={nodeId}
    />
  );
};
