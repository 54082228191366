import React, { Component } from "react";
import FirebaseService from "../services/FirebaseService";
import { User } from "../types/UserTypes";

/****** TODO: change this to just firebaseService */
const FirebaseContext = React.createContext({
  firebaseService: new FirebaseService(),
  isLoggedIn: false,
  user: null,
});

export interface FirebaseProviderState {
  firebaseService: FirebaseService;
  isLoggedIn: boolean;
  user: User;
}

class FirebaseProvider extends Component {
  state: FirebaseProviderState = {
    firebaseService: new FirebaseService(),
    isLoggedIn: false,
    user: null,
  };

  componentDidMount = () => {
    this.state.firebaseService.auth.onAuthStateChanged((user: any) => {
      if (user && user.emailVerified) {
        // user is logged in
        this.setState({
          firebaseService: this.state.firebaseService,
          isLoggedIn: true,
          user: {
            displayName: user.displayName,
            email: user.email,
            uid: user.uid,
            photoUrl: user.photoUrl,
          },
        });
      } else {
        this.setState({
          firebaseService: this.state.firebaseService,
          isLoggedIn: false,
          user: null,
        });
      }
    });
  };

  render() {
    const { children } = this.props;
    const { firebaseService, isLoggedIn, user } = this.state;

    ///// TODO: update provider to just firebase service
    return (
      <FirebaseContext.Provider
        value={{
          firebaseService: firebaseService,
          isLoggedIn: isLoggedIn,
          user: user,
        }}
      >
        {children}
      </FirebaseContext.Provider>
    );
  }
}

export default FirebaseContext;

export { FirebaseProvider };
