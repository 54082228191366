import React from "react";
import StorageService from "../services/StorageService";

const StorageContext = React.createContext(new StorageService());

export default StorageContext;
/*
export interface StorageProviderState {
  storageService: StorageService;
}

class StorageProvider extends Component {
  state: StorageProviderState = {
    storageService: new StorageService(),
  };

  render() {
    const { children } = this.props;
    const { storageService } = this.state;
    const {} = this;

    return (
      <StorageContext.Provider value={storageService}>
        {children}
      </StorageContext.Provider>
    );
  }
}

export default StorageContext;

export { StorageProvider };
*/
