import {
  IonButton,
  IonContent,
  IonHeader,
  IonLabel,
  IonPage,
  IonSlide,
  IonSlides,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useState } from "react";
import { useHistory } from "react-router";

const IntroSlides = () => {
  const slideData = [
    { title: "Welcome!", text: "", image: "" },
    { title: "Systems", text: "", image: "" },
    { title: "Nodes", text: "", image: "" },
    { title: "Reference Videos", text: "", image: "" },
    { title: "System Nodes", text: "", image: "" },
    { title: "Navigation", text: "", image: "" },
    { title: "Sharing Systems", text: "", image: "" },
    { title: "Get Ready!", text: "", image: "" },
  ];
  const slidesLength = slideData.length;

  const history = useHistory();

  const [currentSlideNum, setCurrentSlideNum] = useState<number>(1);
  const [nextButtonText, setNextButtonText] = useState<string>("Next");
  const [showSkipText, setShowSkipText] = useState<boolean>(true);

  // Adapted from source: https://stackoverflow.com/a/63789201
  const [swiper, setSwiper] = useState<any>({});
  const init = async function (this: any) {
    setSwiper(await this.getSwiper());
  };

  const slideOpts = {
    initialSlide: 0,
    speed: 400,
  };

  const synchronizeSlideNumber = () => {
    setCurrentSlideNum(swiper.activeIndex + 1);

    if (swiper.activeIndex + 1 >= slidesLength) {
      setNextButtonText("Finish");
      setShowSkipText(false);
    }
  };

  const handleSlidesControlNext = () => {
    if (swiper.activeIndex + 1 >= slidesLength) {
      history.replace("/library");
    } else {
      swiper.slideNext(300);
    }
  };

  return (
    <IonPage>
      <IonHeader color="light">
        <IonToolbar>
          <IonTitle>
            {slideData[currentSlideNum - 1].title}{" "}
            <span style={{ fontSize: "smaller", fontWeight: "lighter" }}>
              ({currentSlideNum.toString() + "/" + slidesLength.toString()})
            </span>
          </IonTitle>
          <IonButton slot="end" fill="clear" size="default">
            <IonLabel
              color="primary"
              onClick={() => {
                handleSlidesControlNext();
              }}
            >
              <h5>{nextButtonText}</h5>
            </IonLabel>
          </IonButton>
          {showSkipText && (
            <IonButton slot="start" fill="clear" size="default">
              <IonLabel
                color="secondary"
                onClick={() => {
                  history.replace("/library");
                }}
              >
                <h5>Skip</h5>
              </IonLabel>
            </IonButton>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={true} className="ion-padding" scrollY={false}>
        <IonSlides
          options={slideOpts}
          onIonSlidesDidLoad={init}
          onIonSlideDidChange={() => synchronizeSlideNumber()}
        >
          <IonSlide>
            <div className="slide">
              <div style={{ height: "40vh" }}>
                {currentSlideNum === 1 && (
                  <picture>
                    <source
                      srcSet="https://firebasestorage.googleapis.com/v0/b/buddytape-c4a21.appspot.com/o/Tutorial%2Ffirst_example_bev.webp?alt=media&token=eaa0e323-6e5b-4fee-add2-8381bb6257c8"
                      type="image/webp"
                    />
                    <source
                      srcSet="https://firebasestorage.googleapis.com/v0/b/buddytape-c4a21.appspot.com/o/Tutorial%2Ffirst_example_bev.png?alt=media&token=8487dde2-f860-44fc-9be4-8bacef7b2044"
                      type="image/png"
                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/buddytape-c4a21.appspot.com/o/Tutorial%2Ffirst_example_bev.png?alt=media&token=8487dde2-f860-44fc-9be4-8bacef7b2044"
                      alt="BuddyTape is a tool for building flow charts"
                    />
                  </picture>
                )}
              </div>
              <p>
                <b>BuddyTape</b> is a free mobile-optimized tool for building,
                organizing, and sharing your BJJ training as <b>flow charts</b>.
                🍍
              </p>
              <p>Let's walk through some features to help you get started!</p>
            </div>
          </IonSlide>
          <IonSlide>
            <div className="slide">
              <div style={{ height: "55vh" }}>
                {currentSlideNum === 2 && (
                  <picture>
                    <source
                      srcSet="https://firebasestorage.googleapis.com/v0/b/buddytape-c4a21.appspot.com/o/Tutorial%2F1_System-Creation.webp?alt=media&token=d5f666c2-fcfe-40b7-964b-e7f271db4a77"
                      type="image/webp"
                    />
                    <source
                      srcSet="https://firebasestorage.googleapis.com/v0/b/buddytape-c4a21.appspot.com/o/Tutorial%2F1_System-Creation.gif?alt=media&token=9a3cb527-320e-4a37-82a4-00c0706f27b9"
                      type="image/gif"
                    />
                    <img
                      alt="Creating a System"
                      src="https://firebasestorage.googleapis.com/v0/b/buddytape-c4a21.appspot.com/o/Tutorial%2F1_System-Creation.gif?alt=media&token=9a3cb527-320e-4a37-82a4-00c0706f27b9"
                    />
                  </picture>
                )}
              </div>
              <p>
                A <b>system</b> is an organized set of nodes. Use the blue
                button at the bottom to create a system!
              </p>
            </div>
          </IonSlide>
          <IonSlide>
            <div className="slide">
              <div style={{ height: "55vh" }}>
                {currentSlideNum === 3 && (
                  <picture>
                    <source
                      srcSet="https://firebasestorage.googleapis.com/v0/b/buddytape-c4a21.appspot.com/o/Tutorial%2F2_Create-Node.webp?alt=media&token=aac6ec62-d8b2-434e-a6df-2e24a54f208f"
                      type="image/webp"
                    />
                    <source
                      srcSet="https://firebasestorage.googleapis.com/v0/b/buddytape-c4a21.appspot.com/o/Tutorial%2F2_Create-Node.gif?alt=media&token=c90641bf-0982-4a4c-a1c7-578a6ef68ce6"
                      type="image/gif"
                    />
                    <img
                      alt="Creating Nodes"
                      src="https://firebasestorage.googleapis.com/v0/b/buddytape-c4a21.appspot.com/o/Tutorial%2F2_Create-Node.gif?alt=media&token=c90641bf-0982-4a4c-a1c7-578a6ef68ce6"
                    />
                  </picture>
                )}
              </div>
              <p>
                Selecting <b>node</b> will load more of its details. Use the
                blue button in the corner to add and connect a new node.
              </p>
            </div>
          </IonSlide>
          <IonSlide>
            <div className="slide">
              <div style={{ height: "55vh" }}>
                {currentSlideNum === 4 && (
                  <picture>
                    <source
                      srcSet="https://firebasestorage.googleapis.com/v0/b/buddytape-c4a21.appspot.com/o/Tutorial%2F3_Video-Node.webp?alt=media&token=c7c3c922-5d4e-4092-b546-18dc75307d63"
                      type="image/webp"
                    />
                    <source
                      srcSet="https://firebasestorage.googleapis.com/v0/b/buddytape-c4a21.appspot.com/o/Tutorial%2F3_Video-Node.gif?alt=media&token=1d01978d-8045-4292-a9a1-4caeffb1fcd0"
                      type="image/gif"
                    />
                    <img
                      alt="Reference Videos"
                      src="https://firebasestorage.googleapis.com/v0/b/buddytape-c4a21.appspot.com/o/Tutorial%2F3_Video-Node.gif?alt=media&token=1d01978d-8045-4292-a9a1-4caeffb1fcd0"
                    />
                  </picture>
                )}
              </div>
              <p>
                When creating/editing nodes, you can optionally specify a{" "}
                <b>YouTube</b> video and timestamp to organize instructionals!
              </p>
            </div>
          </IonSlide>
          <IonSlide>
            <div className="slide">
              <div style={{ height: "55vh" }}>
                {currentSlideNum === 5 && (
                  <picture>
                    <source
                      srcSet="https://firebasestorage.googleapis.com/v0/b/buddytape-c4a21.appspot.com/o/Tutorial%2F4_Link-Systems.webp?alt=media&token=86ce58fd-e608-4ad6-9005-ce51856afdbe"
                      type="image/webp"
                    />
                    <source
                      srcSet="https://firebasestorage.googleapis.com/v0/b/buddytape-c4a21.appspot.com/o/Tutorial%2F4_Link-Systems.gif?alt=media&token=33482bb8-2599-46d1-aef0-3d48d5ad96b0"
                      type="image/gif"
                    />
                    <img
                      alt="System Nodes"
                      src="https://firebasestorage.googleapis.com/v0/b/buddytape-c4a21.appspot.com/o/Tutorial%2F4_Link-Systems.gif?alt=media&token=33482bb8-2599-46d1-aef0-3d48d5ad96b0"
                    />
                  </picture>
                )}
              </div>
              <p>
                When you have multiple systems, <b>System</b>-type nodes link
                them together to help keep things organized.
              </p>
            </div>
          </IonSlide>
          <IonSlide>
            <div className="slide">
              <div style={{ height: "55vh" }}>
                {currentSlideNum === 6 && (
                  <picture>
                    <source
                      srcSet="https://firebasestorage.googleapis.com/v0/b/buddytape-c4a21.appspot.com/o/Tutorial%2F5_System-Navigation.webp?alt=media&token=361277ac-18be-46c7-917e-a60f895a7eb5"
                      type="image/webp"
                    />
                    <source
                      srcSet="https://firebasestorage.googleapis.com/v0/b/buddytape-c4a21.appspot.com/o/Tutorial%2F5_System-Navigation.gif?alt=media&token=a8ff8856-ac8d-4aad-8f81-253a27ca9f58"
                      type="image/gif"
                    />
                    <img
                      alt="Navigating a System"
                      src="https://firebasestorage.googleapis.com/v0/b/buddytape-c4a21.appspot.com/o/Tutorial%2F5_System-Navigation.gif?alt=media&token=a8ff8856-ac8d-4aad-8f81-253a27ca9f58"
                    />
                  </picture>
                )}
              </div>
              <p>
                You can <i>drag</i>, <i>zoom</i>, <i>fit-to-view</i>, and{" "}
                <i>expand node details</i>.
              </p>
            </div>
          </IonSlide>
          <IonSlide>
            <div className="slide">
              <div style={{ height: "55vh" }}>
                {currentSlideNum === 7 && (
                  <picture>
                    <source
                      srcSet="https://firebasestorage.googleapis.com/v0/b/buddytape-c4a21.appspot.com/o/Tutorial%2F6_Share-System.webp?alt=media&token=0c541e03-406b-49c4-b7f1-29dfc760a209"
                      type="image/webp"
                    />
                    <source
                      srcSet="https://firebasestorage.googleapis.com/v0/b/buddytape-c4a21.appspot.com/o/Tutorial%2F6_Share-System.gif?alt=media&token=2765ad72-8049-44c6-a503-ef28a58c9f2b"
                      type="image/gif"
                    />
                    <img
                      alt="Sharing a System"
                      src="https://firebasestorage.googleapis.com/v0/b/buddytape-c4a21.appspot.com/o/Tutorial%2F6_Share-System.gif?alt=media&token=2765ad72-8049-44c6-a503-ef28a58c9f2b"
                    />
                  </picture>
                )}
              </div>
              <p>
                You can <b>share</b> your system by using the menu!
              </p>
              <p style={{ fontSize: "smaller" }}>
                Shared systems are read-only. Try it out:
                <br />
                <a href="https://www.buddytape.app/s/3VURAjEa" target="_blank" rel="noreferrer">
                  Lachlan Giles: Side Control Escapes
                </a>
              </p>
            </div>
          </IonSlide>
          <IonSlide>
            <div className="slide">
              <div style={{ height: "40vh" }}>
                {currentSlideNum === 8 && (
                  <picture>
                    <source
                      srcSet="https://firebasestorage.googleapis.com/v0/b/buddytape-c4a21.appspot.com/o/Tutorial%2Ffin_get_started-min.webp?alt=media&token=0f0e2edb-cbf0-42e7-a8a0-94b0ac13f5e1"
                      type="image/webp"
                    />
                    <source
                      srcSet="https://firebasestorage.googleapis.com/v0/b/buddytape-c4a21.appspot.com/o/Tutorial%2Ffin_get_started-min.png?alt=media&token=f1e982e2-7620-4453-8d1d-24f13245ac72"
                      type="image/png"
                    />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/buddytape-c4a21.appspot.com/o/Tutorial%2Ffin_get_started-min.png?alt=media&token=f1e982e2-7620-4453-8d1d-24f13245ac72"
                      alt="Get started!"
                    />
                  </picture>
                )}
              </div>
              <p>
                You can access the menu to manage your account, revisit this
                tutorial, or contact us with feedback!
              </p>
              <h2>1...2...3...👏</h2>
            </div>
          </IonSlide>
        </IonSlides>
      </IonContent>
    </IonPage>
  );
};

export default IntroSlides;
