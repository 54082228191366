// @ts-nocheck
import { isNode } from "react-flow-renderer";
import dagre from "dagre";
import { NodeType } from "../types/NodeTypes";

// Source: https://reactflow.dev/examples/layouting/
const initializeDagreGraph = () => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  return dagreGraph;
};

const calculateSystemDimensions = (nodeData: NodeType) => {
  // Use the number of words to calculate the height of the title node
  if (nodeData.metadata.type === "title") {
    const wordCount = nodeData.metadata.name.split(" ").length;
    return {
      width: 150,
      height: Math.ceil(wordCount / 3) * 55,
    };
  } else {
    return {
      width: 150,
      height: 50,
    };
  }
};

export const getLayoutedElements = (
  elements: Array<NodeType>,
  direction = "TB",
  dagreGraph = null
) => {
  if (!dagreGraph) {
    dagreGraph = initializeDagreGraph();
  }

  const isHorizontal = direction === "LR";
  dagreGraph.setGraph({ rankdir: direction });
  elements.forEach((e: NodeType) => {
    if (isNode(e)) {
      dagreGraph.setNode(e.id, calculateSystemDimensions(e));
    } else {
      dagreGraph.setEdge(e.source, e.target);
    }
  });
  dagre.layout(dagreGraph);
  return elements.map((e: NodeType) => {
    if (isNode(e)) {
      const nodeWithPosition = dagreGraph.node(e.id);
      e.targetPosition = isHorizontal ? "left" : "top";
      e.sourcePosition = isHorizontal ? "right" : "bottom";
      // unfortunately we need this little hack to pass a slightly different position
      // in order to notify react flow about the change
      e.position = {
        x: nodeWithPosition.x + Math.random() / 1000,
        y: nodeWithPosition.y,
      };
    }
    return e;
  });
};
