import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

import { nanoid } from "nanoid";

import { UserLibrary, System } from "../types/SystemTypes";
import { FormError, User, UserData } from "../types/UserTypes";
import getConfig from "../configs/FirebaseConfigs";
import { RegistrationFields } from "../types/UserTypes";

const SYSTEM_COLLECTION = "systems";
const FEEDBACK_COLLECTION = "feedback";
const USER_COLLECTION = "users";

class FirebaseService {
  auth: firebase.auth.Auth;

  firestore: firebase.firestore.Firestore;

  constructor() {
    if (!firebase.apps.length) {
      firebase.initializeApp(getConfig());
    }

    this.auth = firebase.auth();
    this.firestore = firebase.firestore();
  }

  getFirebase = () => {
    return firebase;
  };

  /*** AUTH ***/
  /*
  private doCreateUserWithEmailAndPassword = (
    email: string,
    password: string
  ) => {
    this.auth.createUserWithEmailAndPassword(email, password);
  };
  */

  doCreateUser = async ({
    name,
    email,
    password,
  }: RegistrationFields): Promise<FormError> => {
    try {
      const userCredential = await this.auth.createUserWithEmailAndPassword(
        email,
        password
      );

      if (userCredential === null) {
        throw new Error("Created user credential had no value");
      }
      await userCredential.user.sendEmailVerification();

      await this.doSetUserData(userCredential.user.uid, { name: name });
      console.log("Successfully set metadata fields");

      // createUserWithEmailAndPassword automatically logs the user in
      // https://stackoverflow.com/a/57767137
      await this.doSignOut();

      return {
        hasError: false,
        message: "Successfully created user",
      };
    } catch (e: any) {
      console.error(`Error creating user: ${e}`);
      return {
        hasError: true,
        message: e.message,
      };
    }
  };

  doSignInWithEmailAndPassword = async (
    email: string,
    password: string
  ): Promise<FormError> => {
    return this.auth
      .signInWithEmailAndPassword(email, password)
      .catch((e: any) => {
        // coerce the Firebase error here
        // Ex. {code: "auth/invalid-email", message: "The email address is badly formatted.", a: null}
        return e.message;
      })
      .then((userCredential) => {
        console.log(userCredential);
        if (userCredential && userCredential.user) {
          return {
            hasError: false,
            message: "Logged in successfully",
          };
        } else {
          if (userCredential.message) {
            return {
              hasError: true,
              message: userCredential.message,
            };
          } else {
            return {
              hasError: true,
              message: "Invalid credentials",
            };
          }
        }
      });
  };

  doFacebookSignIn = async (): Promise<FormError> => {
    const fbAuth = new firebase.auth.FacebookAuthProvider();
    try {
      await this.auth.signInWithPopup(fbAuth);
      console.log("Successfully signed in with Facebook");
      return {
        hasError: false,
        message: "Successfully logged in with Facebook",
      };
    } catch (e: any) {
      console.error(e);
      return {
        hasError: true,
        message: e.message,
      };
    }
  };

  doGoogleSignIn = async (): Promise<FormError> => {
    const gAuth = new firebase.auth.GoogleAuthProvider();
    try {
      await this.auth.signInWithPopup(gAuth);
      console.log("Successfully signed in with Google");
      return {
        hasError: false,
        message: "Successfully logged in with Google",
      };
    } catch (e: any) {
      console.error(e);
      return {
        hasError: true,
        message: e.message,
      };
    }
  };

  doSignOut = async () => await this.auth.signOut();

  doPasswordReset = async (email: string): Promise<FormError> => {
    try {
      await this.auth.sendPasswordResetEmail(email);
      return {
        hasError: false,
        message: `Password reset request sent to ${email}`,
      };
    } catch (e: any) {
      console.error(e);
      return {
        hasError: true,
        message: e.message,
      };
    }
  };

  doPasswordUpdate = (password: string) =>
    this.auth.currentUser.updatePassword(password);

  getUser = (): User => {
    const currentUser = this.auth.currentUser;

    if (!currentUser) {
      return null;
    }

    return {
      displayName: currentUser.displayName,
      email: currentUser.email,
      uid: currentUser.uid,
      photoUrl: currentUser.photoURL,
    };
  };

  isSignedIn = (): boolean => {
    const user = firebase.auth().currentUser;
    if (user) {
      return true;
    } else {
      return false;
    }
  };

  doSetUserData = async (uid: string, userData: UserData) => {
    await this.firestore.collection(USER_COLLECTION).doc(uid).set(userData);
  };

  /*** FIRESTORE ***/
  generateId = (): string => {
    // collision probability: https://zelark.github.io/nano-id-cc/
    return nanoid(8);
  };

  getUserLibrary = async (userId: string): Promise<UserLibrary> => {
    return this.firestore
      .collection(SYSTEM_COLLECTION)
      .where("ownerId", "==", userId)
      .get()
      .then((querySnapshot: firebase.firestore.QuerySnapshot) => {
        const systems: Array<System> = [];
        querySnapshot.forEach(
          (doc: firebase.firestore.QueryDocumentSnapshot) => {
            let data = doc.data();
            data["systemId"] = doc.id;
            systems.push(data as System);
          }
        );
        return {
          userId: userId,
          systems: systems,
        };
      });
  };

  // TODO: Promise<system> instead of <any> to handle unhappy cases
  getSystem = async (systemId: string): Promise<any> => {
    return this.firestore
      .collection(SYSTEM_COLLECTION)
      .doc(systemId)
      .get()
      .then((doc: firebase.firestore.DocumentSnapshot) => {
        if (doc.exists) {
          let data = doc.data();
          return {
            systemId: systemId,
            name: data.name,
            creationTime: data.creationTime,
            modifiedTime: data.modifiedTime,
            ownerId: data.ownerId,
            elements: data.elements,
            tags: data.tags,
          };
        } else {
          // TODO: error handling
          console.log(`Could not find systemId: ${systemId}`);
          return null;
        }
      })
      .catch((e: Error) => {
        console.error(`Error retrieving system: ${e}`);
      });
  };

  doAddSystem = async (system: System): Promise<String> => {
    try {
      const id: string = this.generateId();
      system.systemId = id;

      await this.firestore.collection(SYSTEM_COLLECTION).doc(id).set(system);
      console.log(`${id} created`);
      return id;
    } catch (e: any) {
      console.error(`Error creating system: ${e}`);
      throw e;
    }
  };

  doUpdateSystem = (systemId: string, system: System) => {
    this.firestore
      .collection(SYSTEM_COLLECTION)
      .doc(systemId)
      .update(system)
      .then(() => {
        console.log(`${systemId} successfully updated`);
      })
      .catch((e: Error) => {
        console.error(`Error updating system: ${e}`);
      });
  };

  doDeleteSystem = (systemId: string) => {
    // TODO: Prompt user about confirming and also verify in the view
    this.firestore.collection(SYSTEM_COLLECTION).doc(systemId).delete();
  };

  /*** Feedback ***/
  doSubmitFeedback = async ({
    name,
    email,
    message,
    userId,
  }: any): Promise<void> => {
    const feedback = {
      name: name,
      email: email,
      message: message,
      userId: userId,
      timestamp: new Date(),
    };
    const docRef = await this.firestore
      .collection(FEEDBACK_COLLECTION)
      .add(feedback);
    console.log(`Created feedback ID ${docRef.id}`);
  };
}

export default FirebaseService;
