import { BTImage } from "../types/NodeTypes";

class CloudinaryService {

  constructor() {
  }

  uploadImageFile = async (imageFile: any, userId: string, systemId: string, nodeId: string) => {
    const formData = new FormData();

    formData.append("file", imageFile);
    formData.append("upload_preset", "brxsemp6"); // TODO: abstract to an env config
    formData.append("folder", "bt"); // TODO: abstract to a env config
    formData.append("tags", this.buildImageTags(userId, systemId, nodeId));

    const options = {
      method: "POST",
      body: formData,
    }

    return await fetch("https://api.cloudinary.com/v1_1/dxxrfg2kn/image/upload", options)
      .then(res => res.json())
      .then(res => {
        return {
          id: res.public_id as string,
          imageUrl: res.secure_url as string,
          description: null,
          creationTime: res.created_at as string,
          isActive: true,
          isDeleted: false,
        } as BTImage
      })
      .catch(err => console.error(err));
  }

  private buildImageTags(userId: string, systemId: string, nodeId: string): string {
    const imageTags = [];

    if (userId) {
      imageTags.push(`userId:${userId}`);
    }
    if (systemId) {
      imageTags.push(`systemId:${systemId}`);
    }
    if (nodeId) {
      imageTags.push(`nodeId:${nodeId}`);
    }

    return imageTags.join(",");
  }

}

export default CloudinaryService;
