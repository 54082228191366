import { NodeType } from "./NodeTypes";

export interface System {
  systemId?: string;
  name: string;
  description?: string;
  creationTime: string;
  modifiedTime: string;
  ownerId: string;
  elements?: Array<NodeType>;
  tags?: Array<Tag>;
}

/*
export interface SystemElements {
  elements: Array<NodeType>;
}
*/

export const UntaggedTag: Tag = {
  color: "#f4f5f8",
  name: "Untagged",
}


// Source: https://i.etsystatic.com/23138702/r/il/06169f/2303267918/il_1588xN.2303267918_b0tn.jpg
export const TagColors: Array<string> = [
  "#ea97c1",
  "#f7b3c1",
  "#fdd1b4",
  "#dcd5cc",
  "#efdfc6",
  "#e4efc9",
  "#dce6dc",
  "#c8cedc",
  "#9ad4e0",
  "#8eaddb",
  "#c18bbd",
  "#8c8cc5",
]

export interface Tag {
  name: string;
  color: string;
}

export type TagReference = Record<string, Tag>;

export interface UserLibrary {
  userId: string;
  systems: Array<System>;
}

export type TaggedSystem = Record<string, Array<System>>;
