// @ts-nocheck
// TODO: change type from `element: any` to interface
import { isNode } from "react-flow-renderer";
import { Connection, Video } from "../types/NodeTypes";

// TODO: move these to models once it's set up

export const generateNodeId = () => {
  return `n-${+new Date()}`;
};

export const generateEdgeId = (sourceNodeId: string, targetNodeId: string) => {
  return `e_${sourceNodeId}_${targetNodeId}`;
};

export const createNode = (
  nodeId: string,
  name: string,
  type: string,
  description: string,
  data: object,
  x: int = 200,
  y: int = 200
) => {
  let node = {
    id: nodeId,
    data: { label: name },
    metadata: {
      name: name,
      type: type,
      description: description,
      creationTime: new Date().toISOString(),
      modifiedTime: new Date().toISOString(),
    },
    position: {
      x: x,
      y: y,
    },
  };

  for (const [key, value] of Object.entries(data)) {
    node.metadata[key] = value;
  }

  return node;
};

// TODO: this mutates the state directly
export const updateNode = ({
  nodeData,
  name,
  type,
  description,
  data = {},
}: any): object => {
  if (!(name === null && name === "")) {
    nodeData.metadata.name = name;
    nodeData.data.label = name;
  }
  if (!(type === null && type === "")) {
    nodeData.metadata.type = type;
  }
  if (!(type === null)) {
    nodeData.metadata.description = description;
  }
  if (data && Object.keys(data).length > 0) {
    for (const [key, value] of Object.entries(data)) {
      nodeData.metadata[key] = value;
    }
  }
  nodeData.metadata.modifiedTime = new Date().toISOString();
  setNodeStyle(nodeData);

  nodeData.position = {
    x: nodeData.position.x + Math.random() / 1000,
    y: nodeData.position.y,
  };

  return nodeData;
};

/*
export const putNode = (existingNodes: Array<object>, updatedNode: object): Array<object> => {
  const nodeId = updatedNode.id;
  const index = existingNodes.findIndex(v => v.nodeId === nodeId);
  existingNodes[index] = updatedNode;
  return existingNodes;
}
*/

export const createEdge = (
  sourceNodeId: string,
  targetNodeId: string,
  isCustom: boolean = false
) => {
  return {
    id: generateEdgeId(sourceNodeId, targetNodeId),
    source: sourceNodeId,
    target: targetNodeId,
    isCustom: isCustom,
  };
};

// TODO: clean up to better place than utils file
// TODO: look into if it's possible to populate the value with CSS file
export const setNodeStyle = (element) => {
  if (isNode(element)) {
    if (element.metadata.type === "title") {
      // Process style for a title node
      element["style"] = {
        background: "#ececec",
        border: "0px",
        color: "#626262",
        fontWeight: "500",
        fontSize: "14px",
        paddingLeft: "15px",
        paddingRight: "15px",
        fontFamily: "Verdana, sans-serif",
      };
      //element["data"]["label"] = element["data"]["label"].toUpperCase();
    } else {
      // Process style for a regular node
      let backgroundColor = "white";
      if (element.metadata.type === "action") {
        backgroundColor = "#dde3fd";
      } else if (element.metadata.type === "reaction") {
        backgroundColor = "#ffd3e1";
      } else if (element.metadata.type === "submission") {
        backgroundColor = "#fdcbf1";
      } else if (element.metadata.type === "position") {
        backgroundColor = "#d4f4f6";
      } else if (element.metadata.type === "system") {
        backgroundColor = "#e0c3fc";
      }

      element["style"] = {
        background: backgroundColor,
        border: "0px",
        borderRadius: "5px",
        color: "#626262",
        fontWeight: "400",
        fontFamily: "Verdana, sans-serif",
      };
    }
  } else {
    // Process style for an edge
    element["selectable"] = false;
    element["style"] = {
      strokeWidth: 2,
    };

    // Set edge depending on whether it was user-defined or automatically generated
    if (element.isCustom) {
      element["type"] = "default";
    } else {
      element["type"] = "step";
    }
    element["arrowHeadType"] = "arrowclosed";
  }

  return element;
};

// TODO: possibly decouple node's data from bt data?
// This should just be a wrapper that encapsulates node+edge creation. Don't assume much logic in here.
export function buildConnectedNode(
  sourceNodeId: string,
  name: string,
  type: string,
  description: string = "",
  data: object = {}
) {
  const targetNodeId = generateNodeId();
  const targetNode = createNode(targetNodeId, name, type, description, data);

  const targetEdge = createEdge(sourceNodeId, targetNodeId);

  const targetConnectedNode = [targetNode, targetEdge].map(setNodeStyle);
  return targetConnectedNode;
}

// TODO: messy
export function buildConnectionWithTarget(
  sourceNodeId: string,
  targetNodeId: string
) {
  const targetEdge = createEdge(sourceNodeId, targetNodeId, true);
  return [targetEdge].map(setNodeStyle);
}

// TODO: VERY MESSY -- needs to be refactored with NodeContext in scope
export const isTitleNode = (nodeId, getNodeById) => {
  if (!(nodeId === null || nodeId === undefined)) {
    const node = getNodeById(nodeId);
    if (!(node === null || node === undefined)) {
      return node.metadata.type === "title";
    }
  } else {
    return false;
  }
  return false;
};

export const isValidVideoUrl = (videoUrl: string): boolean => {
  // URL must be a Vimeo or Youtube link
  // catches youtube.com and youtu.be
  if (!videoUrl.includes("youtu") && !videoUrl.includes("vimeo")) {
    return false;
  }

  // source https://stackoverflow.com/a/30971061
  const regexQuery =
    "^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$";
  const url = new RegExp(regexQuery, "i");
  return url.test(videoUrl);
};

export const formatVideoTimestamp = (timestamp: string): string => {
  const splitTimestamp = timestamp.split(":");
  return `${splitTimestamp[0]}h${splitTimestamp[1]}m${splitTimestamp[2]}s`;
};

export const attachTimestamp = (video: Video): string => {
  if (video.url === undefined) {
    return undefined;
  }

  let url = decodeURIComponent(video.url);
  const splitUrl = url.split("?");
  const ytTimestamp = formatVideoTimestamp(video.timestamp);

  let urlParams;
  if (splitUrl.length > 1) {
    urlParams = new URLSearchParams(splitUrl[1]);

    if (video.timestamp === "00:00:00") {
      // Default timestamp means we should keep the video without any timestamps in it
      urlParams.delete("t");
    } else {
      urlParams.set("t", ytTimestamp);
    }
  } else {
    if (video.timestamp !== "00:00:00") {
      urlParams = new URLSearchParams(`t=${ytTimestamp}`);
    }
  }

  if (urlParams) {
    return `${splitUrl[0]}?${urlParams.toString()}`;
  } else {
    return splitUrl[0];
  }
};

// Parse the elements to determine a given node's inbound and outbound connected nodes
// Look through the edges to determine what nodes are connected
export const getConnectedNodes = (nodeId: string, elements: Array<NodeType>): ConnectedNodes => {
  const nodeMap = {};

  const inboundEdges: Array<Connection> = [];
  const outboundEdges: Array<Connection> = [];

  for (let i = 0; i < elements.length; i++) {
    if (isNode(elements[i])) {
      nodeMap[elements[i].id] = elements[i];
      continue;
    }
    if (elements[i].source === nodeId) {
      outboundEdges.push(elements[i]);
    }
    else if (elements[i].target === nodeId) {
      inboundEdges.push(elements[i]);
    }
  }

  return {
    inbound: inboundEdges.map(e => nodeMap[e.source]),
    outbound: outboundEdges.map(e => nodeMap[e.target])
  }
};
