import {
  IonAlert,
  IonBackdrop, // eslint-disable-line @typescript-eslint/no-unused-vars
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonToolbar,
} from "@ionic/react";
import { useContext, useEffect, useRef, useState } from "react";
import { FormError } from "../types/UserTypes";
import SocialAuth from "../components/authentication/SocialAuth";
import { isValidEmail } from "../utils/UserUtils";
import AuthContext, { AuthProviderState } from "../context/AuthContext";
import { useHistory } from "react-router";
import { arrowBack } from "ionicons/icons";

const Login = () => {
  const { authHandler }: AuthProviderState = useContext(AuthContext);
  const history = useHistory();

  const [emailValue, setEmailValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [showResetPassword, setShowResetPassword] = useState<boolean>(false);

  const emailField = useRef(null);
  const passwordField = useRef(null);

  const [errorValue, setErrorValue] = useState<FormError>({
    hasError: true,
    message: "",
  });

  useEffect(() => {
    const unsubscribe = authHandler
      .getFirebaseService()
      .getFirebase()
      .auth()
      .onAuthStateChanged((authUser) => {
        if (authUser) {
          if (authUser.emailVerified) {
            history.replace("/library");
          } else {
            // TODO: toast not logged in
            // showNotVerifiedToast();
            authHandler.doSignOut();
          }
        } else {
          //history.replace("/landing");
        }
      });

    // Unmount subscriber
    return () => unsubscribe();
  }, [authHandler, history]);

  // TODO: validate that e-mail is properly formatted
  const handleSubmit = () => {
    if (emailValue !== emailField.current.value) {
      setEmailValue(emailField.current.value);
    }
    if (passwordValue !== passwordField.current.value) {
      setPasswordValue(passwordField.current.value);
    }

    authHandler
      .doSignInWithEmailAndPassword(emailValue, passwordValue)
      .then((fe: FormError) => {
        setErrorValue(fe);
      });
  };

  return (
    <IonPage>
      <IonAlert
        isOpen={showResetPassword}
        onDidDismiss={() => { }}
        cssClass="my-custom-class"
        header={"Reset Your Password"}
        message={"Enter your email address to receive a password reset email."}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: (v) => { },
          },
          {
            text: "Submit",
            handler: (v) => {
              if (isValidEmail(v.resetEmailValue)) {
                authHandler.doPasswordReset(v.resetEmailValue);
              } else {
                console.log("Invalid e-mail provided.");
              }
            },
          },
        ]}
        inputs={[
          {
            name: "resetEmailValue",
            type: "email",
            placeholder: "myguardgotpassed@buddytape.app",
          },
        ]}
      ></IonAlert>
      <IonContent>
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonButtons slot="start">
              {/* TODO: Detect when this there is no history to go back to */}
              <IonButton color="none" onClick={() => history.goBack()}>
                <IonIcon color="btblue" icon={arrowBack}></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonCard>
          <IonCardContent>
            <IonItem>
              <IonLabel position="stacked">Email Address</IonLabel>
              <IonInput
                type="text"
                value={emailValue}
                ref={(v) => (emailField.current = v)}
                onInput={(e) => {
                  setEmailValue((e.target as HTMLInputElement).value);
                }}
              ></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="stacked">Password</IonLabel>
              <IonInput
                type="password"
                ref={(v) => (passwordField.current = v)}
                value={passwordValue}
                onInput={(e) => {
                  setPasswordValue((e.target as HTMLInputElement).value);
                }}
              ></IonInput>
            </IonItem>
            {errorValue.hasError && (
              <div className="registration-error">
                <IonLabel color="danger">{errorValue.message}</IonLabel>
              </div>
            )}

            <IonRow className="ion-align-items-right">
              <IonCol
                className="ion-text-right"
                style={{ marginRight: "2%", paddingBottom: "10px" }}
              >
                <IonLabel onClick={() => setShowResetPassword(true)}>
                  <small>Forgot Password?</small>
                </IonLabel>
              </IonCol>
            </IonRow>
            <IonGrid>
              <IonRow className="ion-align-items-center">
                <IonCol className="ion-text-center">
                  <IonButton disabled={false} onClick={handleSubmit}>
                    Sign In
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardContent>
        </IonCard>
        <SocialAuth setErrorValue={setErrorValue}></SocialAuth>
      </IonContent>
    </IonPage>
  );
};

export default Login;
